import React, {useRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

export default function UncDemsFirst(props) {
  const classes = useStyles();
  const sliderRef = useRef();

  useEffect(function () {
    if (sliderRef) {
      console.log(sliderRef.current);
      setTimeout(() => sliderRef.current.focus(), 100);
    }
  });

  return (
    <div className={`${classes.root} fadeInUp`} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h6' component='p' className={classes.questionBoxH6}>
            Please click the number (1-10) that best describes your <strong>Dry Eye Symptoms</strong> over the past week.
          </Typography>
        </Box>
        <Box className={classes.questionTitleBox} ref={sliderRef}>
          <strong className={classes.questionTitleBoxH6}>My Dry Eye Symptoms are...</strong>
        </Box>
        <div className={classes.slider}>
          <Slider
            value={props.score}
            step={0.1}
            min={1}
            max={10}
            aria-labelledby='discrete-slider'
            valueLabelDisplay='on'
            marks={marks}
            onChange={(event, value) => props.handleUncDems(event, value)}
          />
        </div>
        <div className={classes.examplesContainer}>
          <span className={props.score > 1.0 && props.score <= 2 ? classes.active : classes.inactive}>Not a problem</span>

          <span className={props.score > 4.0 && props.score <= 6 ? classes.active : classes.inactive}>Moderately bothersome</span>

          <span className={props.score > 8.0 && props.score <= 10 ? classes.active : classes.inactive}>Severe</span>

          <span className={props.score > 2.0 && props.score <= 4 ? classes.active : classes.inactive}>Mild and tolerable</span>

          <span className={props.score > 6.0 && props.score <= 8 ? classes.active : classes.inactive}>Very bothersome</span>
        </div>
        {props.fieldsErrors.assessment1.status && (
          <Typography variant='body1' component='p' className='error'>
            {props.fieldsErrors.assessment1.text}
          </Typography>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
    transition: '.3s all ease-in-out',
  },
  questionBox: {
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  questionBoxH6: {
    color: '#001141 !important',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '2.19rem',
    textAlign: 'center',
  },
  questionTitleBox: {
    padding: '16px 16px',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 16px',
    },
  },
  questionTitleBoxH6: {
    color: '#001141 !important',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '2.19rem',
    textAlign: 'center',
    fontWeight: '600',
  },
  answerOption: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: 30,
    height: 30,
    lineHeight: 30,
    margin: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  examplesContainer: {
    fontSize: '100%',
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '1em',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    color: '#001141 !important',
    fontWeight: '400',
    fontSize: '1.4rem',
    lineHeight: '2.12rem',
    textAlign: 'center',
    fontWeight: '600',
  },
  inactive: {
    display: 'none',
  },
  active: {
    display: 'inline-block',
    fontWeight: 700,
  },
  slider: {
    height: 30,
    width: '100%',
    padding: 16,
    '& .MuiSlider-root .MuiSlider-rail': {
      borderRadius: '8px',
      height: '8px',
      background: 'linear-gradient(90deg, #0089ff 5%, orange 40%, red 100%)',
      background: 'linear-gradient( 90deg, var(--clr-Blue1) 16.67%, var(--clr-Red) 100% )',
      opacity: 1,
    },
    '& .MuiSlider-root .MuiSlider-track': {
      height: '8px',
      borderRadius: '8px',
      background: 'transparent',
    },
    '& .MuiSlider-root .MuiSlider-thumb': {
      marginLeft: 0,
      width: 0,
      height: 0,
    },
    '& .MuiSlider-mark': {
      height: 0,
      width: 0,
      left: 10,
    },
    '& .MuiSlider-root .MuiSlider-markLabel': {
      left: 30,
      fontWeight: 900,
      fontSize: '1.2em',
    },
    '& .MuiSlider-valueLabel': {
      left: 'auto',
      transform: 'scale(1) translateY(10px) !important',
      '& > span': {
        backgroundColor: '#fff',
        border: '2px solid #555',
        fontWeight: 700,
        fontSize: '125%',
      },
    },
  },
}));
