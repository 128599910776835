import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function PatientHistory(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p'>
            Have you ever been diagnosed with:
          </Typography>
        </Box>
        <Box className="makeStyles-diseaseBoxM">
          <Grid component='label' container alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                Dry Eye Disease?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid component='label' container justify='center' alignItems='center'>
                <Grid item>
                  <RadioGroup
                    className={classes.horizontal}
                    aria-label='dryeye'
                    name='dryeye'
                    value={props.patientHistory.dryeye === true ? 'true' : 'false'}
                    onChange={props.handlePatientHistory('dryeye')}>
                    <FormControlLabel className={props.patientHistory.dryeye === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                    <FormControlLabel className={props.patientHistory.dryeye === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="makeStyles-diseaseBoxM">
          <Grid component='label' container alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h5' component='p' className={classes.diseaseLabel}>
                Glaucoma?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid component='label' container justify='center' alignItems='center'>
                <Grid item>
                  <RadioGroup
                    className={classes.horizontal}
                    aria-label='glaucoma'
                    name='glaucoma'
                    value={props.patientHistory.glaucoma === true ? 'true' : 'false'}
                    onChange={props.handlePatientHistory('glaucoma')}>
                    <FormControlLabel className={props.patientHistory.glaucoma === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                    <FormControlLabel className={props.patientHistory.glaucoma === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="makeStyles-diseaseBoxM">
          <Grid component='label' container alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                Cataracts?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid component='label' container justify='center' alignItems='center'>
                <Grid item>
                  <RadioGroup
                    className={classes.horizontal}
                    aria-label='cataracts'
                    name='cataracts'
                    value={props.patientHistory.cataracts === true ? 'true' : 'false'}
                    onChange={props.handlePatientHistory('cataracts')}>
                    <FormControlLabel className={props.patientHistory.cataracts === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                    <FormControlLabel className={props.patientHistory.cataracts === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className="makeStyles-diseaseBoxM">
          <Grid component='label' container alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                Retinal detachment or retinal disease?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid component='label' container justify='center' alignItems='center'>
                <Grid item>
                  <RadioGroup
                    className={classes.horizontal}
                    aria-label='retinal detachment or retinal disease'
                    name='retinal'
                    value={props.patientHistory.retinal === true ? 'true' : 'false'}
                    onChange={props.handlePatientHistory('retinal')}>
                    <FormControlLabel className={props.patientHistory.retinal === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                    <FormControlLabel className={props.patientHistory.retinal === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="makeStyles-diseaseBoxLastM">
          <Grid component='label' container alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                Other eye conditions?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid component='label' container justify='center' alignItems='center'>
                <Grid item>
                  <RadioGroup
                    className={classes.horizontal}
                    aria-label='other eye condition'
                    name='other'
                    value={props.patientHistory.other === true ? 'true' : 'false'}
                    onChange={props.handlePatientHistory('other')}>
                    <FormControlLabel className={props.patientHistory.other === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                    <FormControlLabel className={props.patientHistory.other === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box p={2}>
            {props.patientHistory.other && (
              <TextField
                id='other-eye-condition'
                label='Please explain'
                variant='outlined'
                color='secondary'
                value={props.patientHistory.explanation}
                onChange={props.handlePatientHistory('explanation')}
                fullWidth
                margin='dense'
                InputLabelProps={{shrink: true}}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  questionBox: {
    width: '100%',
    padding: '16px 16px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  diseaseBox: {
    width: '100%',
    padding: '16px 0 0',
    borderBottom: '1px solid #888',
  },
  diseaseBoxLast: {
    width: '100%',
    padding: '16px 0 0',
  },
  diseaseLabel: {
    fontSize: '125%',
    lineHeight: "1.3rem",
    fontSize: "1.2rem !important",
    lineHeight: "1.8rem !important",
    color: "#001141 !important",
    fontWeight: "normal !important",
    textAlign: "left !important",
    padding:"0 16px !important",
    margin: "0 !important",
  },
  helperText: {
    marginTop: '1em',
  },
  horizontal: {
    flexDirection: 'row',
  },
}));
