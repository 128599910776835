import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
//import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import InputMask from 'react-input-mask';
import Geosuggest from 'react-geosuggest';

export default function ShippingAddress(props) {
  const classes = useStyles();

  const fillInAddress = (gmaps) => {
    if (gmaps) {
      const selectedAddress = gmaps.gmaps.address_components;
      props.handleShippingAutoCompleteChange(selectedAddress);
    }
  };

  return (
    <div className={classes.root} noValidate autocomplete='nope'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box className={classes.questionBox}>
            <Typography variant='h5' component='p'>
              Shipping Address
            </Typography>
            <Typography className={classes.instructions} variant='body1' component='p'>
              Your prescription will be shipped to this address if it's approved.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={1} className={classes.fields}>
            <Geosuggest
              autoFocus
              placeholder='Start typing your address...'
              types={['address']}
              country={['US']}
              onSuggestSelect={(gmaps) => fillInAddress(gmaps)}
              highlightMatch={false}
              inputType='search'
              autoComplete='off'
              style={{
                input: {
                  fontSize: '80%',
                  padding: 16,
                  borderRadius: '50px',
                  background: 'rgba(255, 221, 205,.5)',
                },
                suggests: {
                  margin: '0',
                  padding: '0',
                  textAlign: 'left',
                  listStyle: 'none',
                  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                  fontSize: '80%',
                },
                suggestItem: {
                  padding: '8px 16px',
                  borderBottom: '1px solid #ddd',
                },
              }}
            />
          </Box>
        </Grid>
        {props.shippingAddress.street && (
          <Fragment>
            <Grid item xs={12}>
              <Box p={1} className={classes.fields}>
                <TextField
                  className='MuiOutlinedInput-inputM'
                  id='street-address'
                  label='Street Address'
                  variant='outlined'
                  color='secondary'
                  value={props.shippingAddress.street}
                  onChange={props.handleShippingAddressChange('street')}
                  onBlur={() => props.validateShippingAddress('street')}
                  error={props.fieldsErrors.shippingAddressStreet.status}
                  helperText={props.fieldsErrors.shippingAddressStreet.text}
                  required
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={1} className={classes.fields}>
                <TextField
                  className='MuiOutlinedInput-inputM'
                  id='apt-suite'
                  label='Apt/Suite'
                  variant='outlined'
                  color='secondary'
                  value={props.shippingAddress.apt}
                  onChange={props.handleShippingAddressChange('apt')}
                  onBlur={() => props.validateShippingAddress('apt')}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={1} className={classes.fields}>
                <TextField
                  className='MuiOutlinedInput-inputM'
                  id='city'
                  label='City'
                  variant='outlined'
                  color='secondary'
                  value={props.shippingAddress.city}
                  onChange={props.handleShippingAddressChange('city')}
                  onBlur={() => props.validateShippingAddress('city')}
                  error={props.fieldsErrors.shippingAddressCity.status}
                  helperText={props.fieldsErrors.shippingAddressCity.text}
                  required
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={1} className={classes.fields}>
                <InputMask
                  mask='aa'
                  value={props.shippingAddress.state}
                  onChange={props.handleShippingAddressChange('state')}
                  onBlur={() => props.validateShippingAddress('state')}
                  disabled={false}
                  maskChar=' '>
                  {() => (
                    <TextField
                      id='state'
                      label='State'
                      variant='outlined'
                      color='secondary'
                      className={`${classes.stateField} MuiOutlinedInput-inputM`}
                      error={props.fieldsErrors.shippingAddressState.status}
                      helperText={props.fieldsErrors.shippingAddressState.text}
                      required
                      fullWidth
                    />
                  )}
                </InputMask>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={1} className={classes.fields}>
                <InputMask
                  mask='99999'
                  value={props.shippingAddress.zip}
                  onChange={props.handleShippingAddressChange('zip')}
                  onBlur={() => props.validateShippingAddress('zip')}
                  disabled={false}
                  maskChar=' '>
                  {() => (
                    <TextField
                      className='MuiOutlinedInput-inputM'
                      id='zip-code'
                      label='Zip Code'
                      variant='outlined'
                      color='secondary'
                      error={props.fieldsErrors.shippingAddressZip.status}
                      helperText={props.fieldsErrors.shippingAddressZip.text}
                      required
                      fullWidth
                    />
                  )}
                </InputMask>
              </Box>
            </Grid>
            {props.apiErrorPatient.status && (
              <Grid item xs={12}>
                <Box className={classes.section}>
                  <span className={classes.error}>{props.apiErrorPatient.text}</span>
                </Box>
              </Grid>
            )}
          </Fragment>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  questionBox: {
    padding: '16px 64px 32px',
    maxWidth: 320,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 16px 32px',
    },
  },
  error: {
    color: 'red',
    display: 'block',
    marginTop: '20px',
  },
  autoComplete: {
    width: '100%',
  },
  fields: {
    maxWidth: 320,
    margin: '0 auto',
  },
  instructions: {
    paddingTop: 16,
  },
  stateField: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
}));
