import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import rxLogo from '../../205x80Visionology.png';
import rxPadMiddle from '../../rx.svg';
import rxPadBackground from '../../symbolOfCare.png';
import rxSignature from '../../doctorSignature.png';
import Shofner from '../../shofner.jpg';
import Baum from '../../baum.png';

export default function Prescription(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box p={2}>
            <Typography variant='h5' component='h5' className={classes.muiTypographynova}>
              <strong>Your prescription is almost ready.</strong>
            </Typography>
            <Typography variant='h6' component='p' className={classes.muiTypographyh6nova}>
              Your dry eye assessment and medical history will be sent to <strong>Doctor</strong> to review and approve.
            </Typography>
            <Typography variant='h6' component='p' className={classes.muiTypographyh6nova}>
              Click <strong>"Next"</strong> to add your payment details and activate the prescription after Doctor's approval.
              <div class="VisinologyBox mt-4">
                <ul class="VisinologyBoxUl">
                  <li class="TopHead"><span>Visionology monthly membership</span><span>${(props.productDetails.unit_amount / 100)}</span></li>
                  <li class="BoxTxt"><span>{props.productDetails.product.description}</span><span>${(props.productDetails.unit_amount / 100)}</span></li>
                  <li class="BoxTxt"><span>Shipping</span><span class="SpanFree">Free</span></li>
                  <li class="BoxTxt"><span>Doctor Visit (Telemedicine)</span><span class="SpanFree">Free</span></li>
                </ul>
              </div>
              <div class="CancelList"><ul class="CancelListUl"><li><span class="CorrectSvg"><svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.44912 8.42069L1.13019 5.10176L0 6.22399L4.44912 10.6731L14 1.12223L12.8778 0L4.44912 8.42069Z" fill="white"></path></svg></span>Cancel Anytime</li><li><span class="CorrectSvg"><svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.44912 8.42069L1.13019 5.10176L0 6.22399L4.44912 10.6731L14 1.12223L12.8778 0L4.44912 8.42069Z" fill="white"></path></svg> </span>100% Money-Back Guarantee</li><li><span class="CorrectSvg"><svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.44912 8.42069L1.13019 5.10176L0 6.22399L4.44912 10.6731L14 1.12223L12.8778 0L4.44912 8.42069Z" fill="white"></path></svg> </span>Automated Billing So You Never Miss a Refill</li></ul></div>
            </Typography>
            {props.stripeError !== '' && (
            <Typography variant='body1' component='p'>
              <span className={classes.error}>{props.stripeError}</span>
            </Typography>
            )}
          </Box>
          
        </Grid>
        
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  questionBox: {
    padding: '16px 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    '& h5': {
      textAlign: 'center',
    },
  },
  middleTopContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rxSvg: {
    width: '60px',
    flex: '0 0 60px',
    height: 'auto',
    opacity: '0.75',
  },
  muiTypographynova: {
    marginBottom: '1em',
    marginTop: '32px',
    fontFamily: "Ibarra Real Nova !important",
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    fontSize: '2rem !important',
    lineHeight: '2.2rem !important',
    color: '#27345e !important',
  },
  muiTypographyh6nova: {
    marginBottom: '1em',
    fontWeight: '500 !important',
    letterSpacing: '0.0075em !important',
    color: '#27345e !important',
    fontSize: '1.4rem !important',
    lineHeight: '2.2rem !important',
    fontFamily: "Jost !important",
  },
  grayButton: {
    display: 'inline-block',
    margin: '1em auto',
    background: 'rgb(0, 0, 0)',
    border: '1px solid #000',
    fontWeight: '700',
    padding: '1em 2em',
    fontSize: '1.25',
    borderRadius: 50,
    color: '#fff',
    textDecoration: 'none',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: 'rgb(0, 0, 0)',
      background: 'rgba(255, 255, 255, 0.9)',
      boxShadow: '0 0 10px rgba(90, 92, 97, 0.5)',
    },
  },
  signature: {
    width: 100,
    marginBottom: '-12px',
  },
  rxPadTop: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rxPadTopRight: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  userProfile: {
    width: 120,
    height: 120,
    margin: '20px 20px 8px',
    borderRadius: 8,
  },
  userProfile2: {
    width: 120,
    height: 130,
    margin: '20px 20px 8px',
    borderRadius: 8,
  },
  error: {
    textAlign: 'center',
    width: '100%',
    display: 'block',
    color: 'red',
  },
}));
