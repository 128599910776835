import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

const allergyList = [
  'No Known Drug Allergies',
  'Accupril',
  'Aciphex',
  'Actos',
  'Adalat CC',
  'Adderall',
  'Albuterol',
  'Alesse 28',
  'Allegra',
  'Allegra-D',
  'Allopurinol',
  'Alphagan',
  'Alprazolam',
  'Altace',
  'Amaryl',
  'Ambien',
  'Amitriptyline HCL',
  'Amoxicillin Trihydrate',
  'Amoxil',
  'Amphetamine',
  'Amyl nitrite',
  'Anabolic Steroids',
  'Aricept',
  'Atenolol',
  'Atenolol (Mylan)',
  'Atrovent',
  'Augmentin',
  'Avandia',
  'Avapro',
  'Azmacort',
  'Bactroban',
  'Baycol',
  'Balsam of Peru',
  'Biaxin',
  'Buckwheat',
  'Buspar',
  'Cardizem CD',
  'Cardura',
  'Carisoprodol',
  'Ceftin',
  'Cefzil',
  'Celebrex',
  'Celery',
  'Celexa',
  'Cephalexin',
  'Cipro',
  'Claritin',
  'Claritin Reditabs',
  'Claritin-D 12HR',
  'Claritin-D 24HR',
  'Climara',
  'Clonazepam',
  'Clonidine HCL',
  'Cocaine',
  'Combivent',
  'Coumadin',
  'Cozaar',
  'Cyclobenzaprine',
  'Depakote',
  'Detrol',
  'Diazepam',
  'Diflucan',
  'Dilantin',
  'Diovan',
  'Diovan HCT',
  'Effexor XR',
  'Egg',
  'Elocon',
  'Ery-Tab',
  'Evista',
  'Fish',
  'Flomax',
  'Flonase',
  'Flovent',
  'Folic Acid',
  'Fosamax',
  'Fruit',
  'Furosemide',
  'Garlic',
  'Gemfibrozil',
  'Glucophage',
  'Glucotrol XL',
  'Glyburide',
  'Hydrochlorothiazide',
  'Hydrocodone',
  'Hyzaar',
  'Ibuprofen',
  'Imitrex',
  'Isosorbide Mononitrate',
  'K-Dur',
  'Ketamine',
  'Klor-Con 10',
  'Lanoxin',
  'Lescol',
  'Levaquin',
  'Levothroid',
  'Levoxyl',
  'Lipitor',
  'Lo Ovral',
  'Loestrin FE',
  'Lorazepam',
  'Lotensin',
  'Lotrel',
  'Lotrisone',
  'Lysergic Acid Diethylamide',
  'Macrobid',
  'Maize',
  'Marijuana',
  'MDMA',
  'Medroxyprogesterone',
  'Mescaline',
  'Methamphetamine',
  'Methylphenidate',
  'Methylprednisolone',
  'Metoprolol Tartrate',
  'Miacalcin',
  'Milk',
  'Monopril',
  'Morphine',
  'Mustard',
  'Naproxen',
  'Naproxen-Sodium',
  'Nasonex',
  'Neurontin',
  'Nicotine',
  'Norvasc',
  'Oats',
  'Ortho-Cyclen',
  'Ortho-Tri-Cyclen',
  'Oxycodone',
  'OxyContin',
  'Paxil',
  'Peanut',
  'Penicillin VK',
  'Pepcid',
  'Phenergan',
  'Plavix',
  'Plendil',
  'Potassium chloride',
  'Poultry',
  'Pravachol',
  'Prednisone',
  'Premarin',
  'Prempro',
  'Prevacid',
  'Prilosec',
  'Prinivil',
  'Procardia-XL',
  'Promethazine',
  'Propoxyphene',
  'Proventil HFA',
  'Prozac',
  'Psilocybin',
  'Ranitidine',
  'Red meat',
  'Relafen',
  'Remeron',
  'Rice',
  'Risperdal',
  'Ritalin',
  'Rohypnol',
  'Roxicet',
  'Serevent',
  'Serzone',
  'Sesame',
  'Shellfish',
  'Singulair',
  'Soy',
  'Sulfites',
  'Synthroid',
  'Tamoxifen Citrate',
  'Tartrazine',
  'Temazepam',
  'Tiazac',
  'Tobradex',
  'Toprol XL',
  'Trazodone',
  'Tree nut',
  'Triamterene',
  'Trimox',
  'Triphasil',
  'Ultram',
  'Valtrex',
  'Vasotec',
  'Veetids',
  'Verapamil',
  'Viagra',
  'Vicoprofen',
  'Vioxx',
  'Warfarin Sodium',
  'Wellbutrin SR',
  'Wheat',
  'Xalatan',
  'Xenical',
  'Zestoretic',
  'Zestril',
  'Ziac',
  'Zithromax',
  'Zithromax Z-PAK',
  'Zocor',
  'Zoloft',
  'Zyprexa',
  'Zyrtec',
];

export default function Allergies(props) {
  const classes = useStyles();
  const allergies = props.allergies;
  const [noKnownAllergies, setNoKnownAllergies] = useState(false);

  const handleNoKnownAllergies = (event) => {
    setNoKnownAllergies(event.target.checked);
    if (event.target.checked) {
      props.handleSelection(event, ['No Known Drug Allergies']);
    } else {
      props.handleSelection(event, []);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p'>
            Do you have any medication or other known allergies?
          </Typography>
          <br />
          <Typography variant='h5' component='p'>
            If your allergy is not listed, you can type your allergy and press enter.
          </Typography>
          <Box pt={4}>
            <FormControlLabel
              className="checkboxlabel"
              control={<Checkbox color='secondary' checked={noKnownAllergies} onChange={handleNoKnownAllergies} />}
              label="I don't have any drug allergies."
              labelPlacement='end'
            />
          </Box>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id='allergies-selectbox'
              className={classes.selectBox}
              disabled={noKnownAllergies}
              freeSolo
              filterSelectedOptions
              multiple
              value={allergies}
              onChange={(event, value) => props.handleSelection(event, value)}
              options={allergyList}
              renderOption={(allergy) => allergy}
              getOptionLabel={(allergy) => allergy}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' placeholder='Select from the list or start typing...' margin='normal' fullWidth autoFocus />
              )}
            />
          </FormControl>
        </Box>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    minWidth: '100%',
  },
  questionBox: {
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  helperText: {
    marginTop: '1em',
  },
}));
