import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Box from '@material-ui/core/Box';

import Medications from './medications';
import Allergies from './allergies';
import OtherInfo from './otherInfo';
import PatientHistory from './patientHistory';
import UncDemsInstructions from './uncDemsInstructions';
import UncDemsFirst from './uncDemsFirst';
import UncDemsSecond from './uncDemsSecond';
import Review from './review';
import StateSelector from './stateSelector';
import RequestPrescription from './requestPrescription';
import LoginInfoName from './loginInfoName';
import LoginInfoDob from './loginInfoDob';
import LoginInfoContact from './loginInfoContact';
import Evaluation from './evaluation';
import Prescription from './prescription';
import CreditCard from './creditCard';
import ShippingAddress from './shippingAddress';
import BillingAddress from './billingAddress';
import FinalScreen from './finalScreen';
import SelectedDoctor from './selectedDoctor';
import io from 'socket.io-client';
import ReactWS from 'react-wsx';
import { set } from 'date-fns';
import visionologyLogoMark from '../../visionology-logo-mark.svg';

export default function Assessment(props) {
  
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [stateOfResidence, setStateOfResidence] = useState('');
  let fieldsObject;

  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgyfields='))) {
    const vsnlgyFields = document.cookie.split('; ').find((row) => row.startsWith('vsnlgyfields')).split('=')[1];
    fieldsObject = JSON.parse(vsnlgyFields);
  } else {
    fieldsObject = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      communicationMethod: 'both',
      consent: true,
      dob: null,
      verifiedPhone: "",
      phoneOTP: "",
    };
  }

  const [fields, setFields] = useState(fieldsObject);
  const [ccError, setCcError] = useState('');

  const [fieldsErrors, setFieldsErrors] = useState({
    stateSelection: {
      status: null,
      text: '',
    },
    firstName: {
      status: null,
      text: '',
    },
    lastName: {
      status: null,
      text: '',
    },
    phoneNumber: {
      status: null,
      text: '',
    },
    emailAddress: {
      status: null,
      text: '',
    },
    communicationMethod: {
      status: null,
      text: '',
    },
    dob: {
      status: null,
      text: '',
    },
    consent: {
      status: null,
      text: '',
    },
    assessment1: {
      status: null,
      text: '',
    },
    assessment2: {
      status: null,
      text: '',
    },
    gender: {
      status: null,
      text: '',
    },
    ccNumber: {
      status: null,
      text: '',
    },
    expiration: {
      status: null,
      text: '',
    },
    securityCode: {
      status: null,
      text: '',
    },
    shippingAddressStreet: {
      status: null,
      text: '',
    },
    shippingAddressCity: {
      status: null,
      text: '',
    },
    shippingAddressState: {
      status: null,
      text: '',
    },
    shippingAddressZip: {
      status: null,
      text: '',
    },
    billingAddressStreet: {
      status: null,
      text: '',
    },
    billingAddressCity: {
      status: null,
      text: '',
    },
    billingAddressState: {
      status: null,
      text: '',
    },
    billingAddressZip: {
      status: null,
      text: '',
    },
    twilioOTP: {
      status: null,
      text: '',
    },
    cardFName: {
      status: null,
      text: '',
    },
    phoneOTP: {
      status: null,
      text: '',
    },
  });
  
  // GRX Patient ID
  const [grxPatientId, setGrxPatientId] = useState(null);

  // Processing
  const [isprocess, setIsProcess] = useState(false);

  // PRODUCT PRICE
  const [isProduct, setIsProduct] = useState(false);
  const [productDetails, setProductDetails] = useState(null);

  // STRIPE KEY
  const [paymentIntent, setPaymentIntent] = useState();
  const [paymentIntent2, setPaymentIntent2] = useState();
  const [stripeError, setStripeError] = useState();

  // TWILLIO OTP
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [twillioOTP, setPhoneOTP] = useState(12345);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [stripePayMethodId, setStripePayMethodId] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);

  //--Twilio SMS--
  const handleOTPPhoneChange = (prop) => (event) => {
    setVerifyOTP(false);
  };
  const handleResetOTP = (prop) => (event) => {
    sendSMS();
  };
  function sendSMS(isPost = false) {

    var code = Math.floor((Math.random() * 100000) + 1);
    if(isPost === false) {
      setPhoneOTP(code);
    }
    else {
      const leadData = {
        phone: fields.phoneNumber,
        otp: code,
      };
      fetch(`${process.env.REACT_APP_API_URL}/twilio/sendsms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then(({status, json, ok}) => {
        switch (status) {
          case 201:
          case 200:
            switch (json.code) {
              case 200:
                setPhoneOTP(code);
              break;
              case 400:
                setFieldsErrors((fieldsErrors) => {
                  return {
                    ...fieldsErrors,
                    phoneOTP: {
                      status: true,
                      text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
                    },
                  };
                });
              break;
              case 500:
                setFieldsErrors((fieldsErrors) => {
                  return {
                    ...fieldsErrors,
                    phoneOTP: {
                      status: true,
                      text: json.message,
                    },
                  };
                });
              break;
            }
            break;
          case 400:
            setFieldsErrors((fieldsErrors) => {
              return {
                ...fieldsErrors,
                phoneOTP: {
                  status: true,
                  text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
                },
              };
            });
            break;
          case 500:
          default:
            setFieldsErrors((fieldsErrors) => {
              return {
                ...fieldsErrors,
                phoneOTP: {
                  status: true,
                  text: "Internet server error. Please try again or contact us at (844) 637 2020.",
                },
              };
            });
        }
      });
    }
  }
  //---------------
  let uncDemsScoreNumber;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgyuncdems='))) {
    const vsnlgyUncDems = document.cookie.split('; ').find((row) => row.startsWith('vsnlgyuncdems')).split('=')[1];
    uncDemsScoreNumber = JSON.parse(vsnlgyUncDems);
  } else {
    uncDemsScoreNumber = 0;
  }
  const [uncDemsScore, setUncDemsScore] = useState(uncDemsScoreNumber);
  //---------------
  let uncDemsScoreNumber2;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgyuncdems2='))) {
    const vsnlgyUncDems2 = document.cookie.split('; ').find((row) => row.startsWith('vsnlgyuncdems2')).split('=')[1];
    uncDemsScoreNumber2 = JSON.parse(vsnlgyUncDems2);
  } else {
    uncDemsScoreNumber2 = 0;
  }
  const [uncDemsScore2, setUncDemsScore2] = useState(uncDemsScoreNumber2);
  //---------------
  
  // MEDICATIONS
  let medicationsArray;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgymedicationsnew='))) {
    const vsnlgyMedications = document.cookie.split('; ').find((row) => row.startsWith('vsnlgymedicationsnew')).split('=')[1];
    medicationsArray = JSON.parse(vsnlgyMedications);
  } else {
    medicationsArray = [];
  }
  const [medications, setMedications] = useState(medicationsArray);
  //---------------

  // ALLERGIES
  let allergiesArray;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgyallergiesnew='))) {
    const vsnlgyAllergies = document.cookie.split('; ').find((row) => row.startsWith('vsnlgyallergiesnew')).split('=')[1];
    allergiesArray = JSON.parse(vsnlgyAllergies);
  } else {
    allergiesArray = [];
  }
  const [allergies, setAllergies] = useState(allergiesArray);
  //---------------

  // GENDER
  let genderObject;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgygender='))) {
    const vsnlgyGender = document.cookie.split('; ').find((row) => row.startsWith('vsnlgygender')).split('=')[1];
    genderObject = JSON.parse(vsnlgyGender);
  } else {
    genderObject = {
      female: 'outlined',
      male: 'outlined',
    };
  }
  const [gender, setGender] = useState(genderObject);
  //---------------

  // PREGNANT
  let pregnantBoolean;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgypregnant='))) {
    const vsnlgyPregnant = document.cookie.split('; ').find((row) => row.startsWith('vsnlgypregnant')).split('=')[1];
    pregnantBoolean = JSON.parse(vsnlgyPregnant);
  } else {
    pregnantBoolean = false;
  }
  const [pregnant, setPregnant] = useState(pregnantBoolean);
  //---------------

  // SMOKER
  let smokerBoolean;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgysmoker='))) {
    const vsnlgySmoker = document.cookie.split('; ').find((row) => row.startsWith('vsnlgysmoker')).split('=')[1];
    smokerBoolean = JSON.parse(vsnlgySmoker);
  } else {
    smokerBoolean = false;
  }
  const [smoker, setSmoker] = useState(smokerBoolean);
  //---------------

  // CONTACT LENSES
  let contactLensBoolean;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgycontactlens='))) {
    const vsnlgyContactLens = document.cookie.split('; ').find((row) => row.startsWith('vsnlgycontactlens')).split('=')[1];
    contactLensBoolean = JSON.parse(vsnlgyContactLens);
  } else {
    contactLensBoolean = false;
  }
  const [contactLens, setContactLens] = useState(contactLensBoolean);
  //---------------

  // PATIENT HISTORY
  let patientHistoryObject;
  if (document.cookie.split(';').some((item) => item.trim().startsWith('vsnlgypatienthistory='))) {
    const vsnlgyPatientHistory = document.cookie.split('; ').find((row) => row.startsWith('vsnlgypatienthistory')).split('=')[1];
    patientHistoryObject = JSON.parse(vsnlgyPatientHistory);
  } else {
    patientHistoryObject = {
      glaucoma: false,
      cataracts: false,
      dryeye: false,
      retinal: false,
      other: false,
      explanation: '',
    };
  }
  const [patientHistory, setPatientHistory] = useState(patientHistoryObject);
  //---------------

  const [patientId, setPatientId] = useState('');
  const [isDoctor, setIsDoctor] = useState(false);
  const [doctor, setDoctor] = useState({ id: '', lastName: ''});
  const [creditCard, setCreditCard] = useState({ ccNumber: '', expiration: '', securityCode: '', cardFName: '', cardLName: ''});
  const [billingAddress, setBillingAddress] = useState({ street: '', apt: '', city: '', state: '', zip: ''});
  const [shippingAddress, setShippingAddress] = useState({ street: '', apt: '', city: '', state: '', zip: ''});
  //---------------

  //ERROR HANDLING
  const [apiErrorPatient, setApiErrorPatient] = useState({
    status: false, text: '',
  });

  const [apiErrorShipping, setApiErrorShipping] = useState({
    status: false, text: '',
  });

  const [apiErrorPayment, setApiErrorPayment] = useState({
    status: false, text: '',
  });
  //---------------

  //HANDLING INPUTS
  const handleTextFieldChange = (prop) => (event) => {
    setFields({...fields, [prop]: event.target.value});
  };

  const handleConsentChange = (event) => {
    setFields({...fields, [event.target.name]: event.target.checked});
    if (event.target.checked) {
      setFieldsErrors({
        ...fieldsErrors,
        consent: {
          status: false,
          text: '',
        },
      });
    } else {
      setFieldsErrors({
        ...fieldsErrors,
        consent: {
          status: true,
          text: 'You must agree to the terms and policies to continue.',
        },
      });
    }
  };

  const handleDateChange = (value) => {
    if (value.trim().length === 10) {
      setFields({
        ...fields,
        dob: new Date(value).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      });
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          dob: {
            status: false,
            text: '',
          },
        };
      });
    } else if (value.trim().length > 2) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          dob: {
            status: true,
            text: 'Full Date of Birth is required.',
          },
        };
      });
    }
  };

  const handleMedicationSelection = (event, values) => {
    setMedications(values);
  };

  const handleMedicationTextFieldChange = (prop) => (event) => {
    const value = event.target.value;
    setMedications(value.split(','));
  };

  const handleAllergySelection = (event, values) => {
    setAllergies(values);
  };

  const handleAllergyTextFieldChange = (prop) => (event) => {
    const value = event.target.value;
    setAllergies(value.split(','));
  };

  const handleGender = (prop) => (event) => {
    if (gender[prop] === 'outlined' && prop === 'male') {
      setGender({male: 'contained', female: 'outlined'});
    } else if (gender[prop] === 'contained' && prop === 'male') {
      setGender({...gender, male: 'outlined'});
    } else if (gender[prop] === 'outlined' && prop === 'female') {
      setGender({female: 'contained', male: 'outlined'});
    } else if (gender[prop] === 'contained' && prop === 'female') {
      setGender({...gender, female: 'outlined'});
    } else {
    }
  };

  const handlePregnant = (prop) => (event) => {
    const setToTrue = event.target.value === 'true';
    setPregnant(setToTrue);
  };

  const handleSmoker = (prop) => (event) => {
    const setToTrue = event.target.value === 'true';
    setSmoker(setToTrue);
  };

  const handleContactLens = (prop) => (event) => {
    const setToTrue = event.target.value === 'true';
    setContactLens(setToTrue);
  };

  const handlePatienHistory = (prop) => (event) => {
    if (prop === 'explanation') {
      setPatientHistory({...patientHistory, [prop]: event.target.value});
    } else {
      const setToTrue = event.target.value === 'true';
      setPatientHistory({...patientHistory, [prop]: setToTrue});
    }
  };

  const handleUncDems = (event, value) => {
    setUncDemsScore(value);
    if (fieldsErrors.assessment1) {
      if (value > 1) {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            assessment1: {
              status: false,
              text: '',
            },
          };
        });
      }
    }
  };

  const handleUncTextFieldChange = (prop) => (event) => {
    setUncDemsScore(event.target.value);
  };

  const handleUncDems2 = (event, value) => {
    setUncDemsScore2(value);
    if (fieldsErrors.assessment2) {
      if (value > 1) {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            assessment2: {
              status: false,
              text: '',
            },
          };
        });
      }
    }
  };

  const handleUnc2TextFieldChange = (prop) => (event) => {
    setUncDemsScore2(event.target.value);
  };

  const handleCreditCardChange = (prop) => (event) => {
    setCreditCard({...creditCard, [prop]: event.target.value});
  };

  const handleShippingAddressChange = (prop) => (event) => {
    setShippingAddress({...shippingAddress, [prop]: event.target.value});
  };

  const handleShippingAutoCompleteChange = (addressObject) => {
    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      sublocality_level_1: 'long_name',
      administrative_area_level_1: 'short_name',
      postal_code: 'short_name',
    };

    let completeStreetAddress = '';
    let city = '';
    let state = '';
    let zip = '';

    for (const component of addressObject) {
      const addressType = component.types[0];
      if (addressType === 'sublocality_level_1') {
        city = component[componentForm[addressType]];
      } else if (addressType === 'street_number') {
        completeStreetAddress = component[componentForm[addressType]];
      } else if (addressType === 'route') {
        completeStreetAddress = `${completeStreetAddress} ${component[componentForm[addressType]]}`;
      } else if (addressType === 'locality') {
        city = component[componentForm[addressType]];
      } else if (addressType === 'administrative_area_level_1') {
        state = component[componentForm[addressType]];
      } else if (addressType === 'postal_code') {
        zip = component[componentForm[addressType]];
      }
    }

    setShippingAddress({
      street: completeStreetAddress,
      apt: '',
      city: city,
      state: state,
      zip: zip,
    });
  };
  //---------------

  //ASSIGN DOCTOR
  if(isDoctor === false)
  {
      const leadData = {};
      fetch(`${process.env.REACT_APP_API_URL}/doctor/assign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then(({status, json, ok}) => {
        switch (status) {
          case 201:
          case 200:
            setDoctor(json.response[0]);
            setIsDoctor(true);
            break;
          case 400:
            break;
          case 500:
          default:
        }
      });
  }
  //---------------

  const handleNext = (prop) => {

    let IntercomGender = '';
    if (gender.female === 'contained') {
      if (gender.male === 'contained') {
        IntercomGender = 'U';
      } else {
        IntercomGender = 'F';
      }
    } else {
      if (gender.male === 'contained') {
        IntercomGender = 'M';
      } else {
        IntercomGender = 'U';
      }
    }

    function createLead(isPost = false) {

      if(isPost === false)
      {
        setPatientId(Math.floor(Math.random() * 100));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      else
      {
        setIsProcess(true);
        const leadData = {
          first_name: fields.firstName,
          last_name: fields.lastName,
          phone: fields.phoneNumber,
          email: fields.emailAddress,
          dob: fields.dob,
          state: stateOfResidence,
        };
        fetch(`${process.env.REACT_APP_API_URL}/patients/add-patient-lead`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(leadData),
        })
        .then((response) => {
          return new Promise((resolve) =>
            response.json().then((json) =>
              resolve({
                status: response.status,
                ok: response.ok,
                json,
              })
            )
          );
        })
        .then(({status, json, ok}) => {
          switch (status) {
            case 201:
            case 200:
              setPatientId(json.response.id);
              if(json.response.stripe_customer_id != undefined && json.response.stripe_customer_id != null) {
                setStripeCustomerId(json.response.stripe_customer_id);
              }
              if(json.response.grx_patient_id != undefined && json.response.grx_patient_id != null) {
                setGrxPatientId(json.response.grx_patient_id);
              }
              if(stripeCustomerId !== null || stripeCustomerId !== '')
              {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
              break;
            case 400:
              setStripeError("There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.");
              break;
            case 500:
            default:
              setStripeError("There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.");
          }
        });
        setIsProcess(false);
      }
    }

    function updateLead(step = 1, isPost = false, isNext = true, isGrxSent = false) {
      if(isPost === false)
      {
        setActiveStep((prevActiveStep) => prevActiveStep + step);
      }
      else
      {
        const leadData = {
          id: patientId,
          first_name: fields.firstName,
          last_name: fields.lastName,
          phone: fields.phoneNumber,
          state: stateOfResidence,
          email: fields.emailAddress,
          gender: IntercomGender,
          dob: fields.dob,
          address_shipping: {
            street: `${shippingAddress.street} ${shippingAddress.apt}`,
            city: shippingAddress.city,
            state: shippingAddress.state,
            zip: shippingAddress.zip,
          },
          address_billing: {
            street: `${billingAddress.street} ${billingAddress.apt}`,
            city: billingAddress.city,
            state: billingAddress.state,
            zip: billingAddress.zip,
          },

          smoker: smoker,
          consent: true,
          pregnant: pregnant,
          allergies: allergies,
          medications: medications,
          dryeye: patientHistory.dryeye,
          retinal: patientHistory.retinal,
          other: patientHistory.other,
          contacts: contactLens,
          glaucoma: patientHistory.glaucoma,
          cataracts: patientHistory.cataracts,
          uncDemsScore: uncDemsScore,
          uncDemsScore2: uncDemsScore2,
          uncDemsAvgScore: uncDemsScoreCalculated,
          other_diagnosis: patientHistory.explanation,
          existing_conditions: ['No pre-existing conditions'],
          communication_preference: 'both',
          stripe_customer_id: stripeCustomerId,
          isGrxSent: isGrxSent
        };
        fetch(`${process.env.REACT_APP_API_URL}/patients/update-patient-lead`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(leadData),
        })
        .then((response) => {
          return new Promise((resolve) =>
            response.json().then((json) =>
              resolve({
                status: response.status,
                ok: response.ok,
                json,
              })
            )
          );
        })
        .then(({status, json, ok}) => {
          switch (status) {
            case 201:
            case 200:
              if(json.response.grxPatientID != undefined && json.response.grxPatientID != null) {
                setGrxPatientId(json.response.grxPatientID);
              }
              if(isGrxSent == true && grxPatientId !== null && isNext) {
                setActiveStep((prevActiveStep) => prevActiveStep + step);
              }
              else if(isGrxSent == false && isNext) {
                setActiveStep((prevActiveStep) => prevActiveStep + step);
              }
              else
              {
                setActiveStep((prevActiveStep) => prevActiveStep + step);
              }
              break;
            case 400:
              setApiErrorPatient({
                status: true,
                text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
              });
              break;
            case 500:
              setApiErrorPatient({
                status: true,
                text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
              });
            default:
              setApiErrorPatient({
                status: true,
                text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
              });
          }
        });
      }
    }

    function getPaymentMethod() {
      setIsProcess(true);
      const leadData = {
        customerId: stripeCustomerId,
      };
      fetch(`${process.env.REACT_APP_API_URL}/stripe/get-payment-method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then(({status, json, ok}) => {
        switch (status) {
          case 201:
          case 200:
            if(json.id != undefined && json.id != null) {
              setStripePayMethodId(json.id);
            }
            setStripeError('');
            break;
          case 400:
            setStripeError(json.error.message);
            break;
          case 500:
          default:
            setStripeError(json.error.message);
        }
      });
      setIsProcess(false);
    }

    function createSubscription() {
      setIsProcess(true);
      const leadData = {
        customerId: stripeCustomerId,
        priceId: productDetails.id
      };
      fetch(`${process.env.REACT_APP_API_URL}/stripe/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then(({status, json, ok}) => {
        switch (status) {
          case 201:
          case 200:
            const {subscriptionId, clientSecret} = json;
            setSubscriptionData({ subscriptionId, clientSecret });
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setStripeError('');
            break;
          case 400:
            setStripeError(json.error.message);
            break;
          case 500:
          default:
            setStripeError(json.error.message);
        }
      });
      setIsProcess(false);
    }

    function proceedAssessment() {
      if(isProduct === false || productDetails === null)
      {
          const leadData = {
          };
          fetch(`${process.env.REACT_APP_API_URL}/stripe/price-list`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(leadData),
          })
          .then((response) => {
            return new Promise((resolve) =>
              response.json().then((json) =>
                resolve({
                  status: response.status,
                  ok: response.ok,
                  json,
                })
              )
            );
          })
          .then(({status, json, ok}) => {
            switch (status) {
              case 201:
              case 200:
                setProductDetails(json.prices[0]);
                setIsProduct(true);
                break;
              case 400:
                
                break;
              case 500:
              default:
            }
          });
      }
      
      // Login Info Name and Phone
      if (activeStep === 0)
      {
        if (
          fields.firstName.trim().length > 1 &&
          fields.lastName.trim().length > 1 &&
          fields.phoneNumber.trim().length === 14 &&
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            fields.emailAddress
          )
        ) {
          if((fields.phoneOTP.trim().length > 1 ) && (fields.verifiedPhone.trim().length > 1 && fields.verifiedPhone != fields.phoneNumber))
          {
            fields.phoneOTP = "";
            fields.verifiedPhone = "";
          }
          if(fields.phoneOTP == twillioOTP)
          {
            fields.verifiedPhone = fields.phoneNumber;
            setVerifyOTP(false);
            createLead(true);
            window.dataLayer.push({
              event: 'step0',
              step: 'Potential patient',
            });
          }
          else
          {
            if(verifyOTP)
            {
              validatePhoneOTP();
            }
            setVerifyOTP(true);
          }
        } else {
          validateFirstName();
          validateLastName();
          validatePhone();
          validateEmail();
        }
      }
      // UNC-DEMS instructions, just proceed
      if (activeStep === 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // UNC-DEMS first question
      if (activeStep === 2) {
        if (uncDemsScore >= 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setFieldsErrors((fieldsErrors) => {
            return {
              ...fieldsErrors,
              assessment1: {
                status: false,
                text: '',
              },
            };
          });
        } else {
          setFieldsErrors((fieldsErrors) => {
            return {
              ...fieldsErrors,
              assessment1: {
                status: true,
                text: 'Please answer the question using your best judgment.',
              },
            };
          });
        }
      }
      // UNC-DEMS second question
      if (activeStep === 3) {
        if (uncDemsScore2 >= 1) {
          window.dataLayer.push({
            event: 'step3',
            step: 'Completed UNC-DEMS assessment',
          });
          updateLead();
          setFieldsErrors((fieldsErrors) => {
            return {
              ...fieldsErrors,
              assessment2: {
                status: false,
                text: '',
              },
            };
          });
        } else {
          setFieldsErrors((fieldsErrors) => {
            return {
              ...fieldsErrors,
              assessment2: {
                status: true,
                text: 'Please answer the question using your best judgment.',
              },
            };
          });
        }
      }
      // Request prescription
      if (activeStep === 4) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      // Patient History
      if (activeStep === 5) {
        window.dataLayer.push({
          event: 'step5',
          step: 'Completed Patient History',
        });
        updateLead();
      }
      // Other Info
      if (activeStep === 6) {
        if (gender.male === 'contained' || gender.female === 'contained') {
          window.dataLayer.push({
            event: 'step6',
            step: 'Completed other questions',
          });
          updateLead();
          setFieldsErrors((fieldsErrors) => {
            return {
              ...fieldsErrors,
              gender: {
                status: false,
                text: '',
              },
            };
          });
        } else {
          setFieldsErrors((fieldsErrors) => {
            return {
              ...fieldsErrors,
              gender: {
                status: true,
                text: 'Please select your gender',
              },
            };
          });
        }
      }
      // Medications
      if (activeStep === 7) {
        window.dataLayer.push({
          event: 'step7',
          step: 'Entered current medications',
        });
        if (medications.length > 0) {
          updateLead();
        } else {
          setMedications(['Not taking any medications']);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      // Allergies
      if (activeStep === 8) {
        window.dataLayer.push({
          event: 'step8',
          step: 'Entered allergies',
        });
        if (allergies.length > 0) {
          updateLead();
        } else {
          setAllergies(['No known drug allergies']);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      // Login Info DOB
      if (activeStep === 9) {
        if (fields.dob && fields.dob.trim().length === 10) {
          window.dataLayer.push({
            event: 'step9',
            step: 'Entered DOB',
          });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          validateDob();
        }
      }

      // Create Patient
      if (activeStep === 10) {
        updateLead(1,true);
      }
      // Prescription
      if (activeStep === 11) {

        if(stripePayMethodId === null) {
          getPaymentMethod();
        }

        if(subscriptionData == null) {
          createSubscription();
        }
        else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      // Shipping
      if (activeStep === 12) {
        if (
          fieldsErrors.shippingAddressStreet.status !== true &&
          fieldsErrors.shippingAddressCity.status !== true &&
          fieldsErrors.shippingAddressState.status !== true &&
          fieldsErrors.shippingAddressZip.status !== true &&
          shippingAddress.street !== '' &&
          shippingAddress.city !== '' &&
          shippingAddress.state !== '' &&
          shippingAddress.zip !== ''
        ) {
          window.dataLayer.push({
            event: 'step12',
            step: 'Entered Shipping Address',
          });
          if(grxPatientId == null) {
            updateLead(1,true,true,true);
          }
          else {
            updateLead(1,true);
          }
        } else {
          validateShippingAddress('street');
          validateShippingAddress('zip');
          validateShippingAddress('city');
          validateShippingAddress('state');
        }
      }
      // Credit Card
      if (activeStep === 13) {
        validateCardFirstName();
        validateCreditCard();
        validateSecurityCode();
        validateExpiration();
        if (
          fieldsErrors.cardFName.status !== true &&
          fieldsErrors.ccNumber.status !== true &&
          fieldsErrors.expiration.status !== true &&
          fieldsErrors.securityCode.status !== true &&
          creditCard.cardFName !== '' &&
          creditCard.ccNumber !== '' &&
          creditCard.securityCode !== '' &&
          creditCard.expiration !== ''
        ) {
          //collectPayment()
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          validateCardFirstName();
          validateCreditCard();
          validateSecurityCode();
          validateExpiration();
        }
      }
      // Final Screen
      if (activeStep === 14) {
        window.location.assign(process.env.REACT_APP_WEBSITE_URL);
      }
    }

    const uncDemsScoreCalculated = (uncDemsScore + uncDemsScore2) / 2;
    proceedAssessment();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const editStep = (activeStepNumber) => {
    setActiveStep(activeStepNumber);
  };

  //VALIDATION
  const validateEmail = () => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        fields.emailAddress
      )
    ) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          emailAddress: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          emailAddress: {
            status: true,
            text: 'Please enter a valid email address.',
          },
        };
      });
    }
  };
  const validatePhone = () => {

    if (fields.phoneNumber.trim().length === 14) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          phoneNumber: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          phoneNumber: {
            status: true,
            text: 'Please enter a valid phone number.',
          },
        };
      });
    }
  };
  const validatePhoneOTP = () => {
    if (fields.phoneOTP.trim().length === 5 && twillioOTP == fields.phoneOTP) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          phoneOTP: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          phoneOTP: {
            status: true,
            text: 'Please enter a valid OTP.',
          },
        };
      });
    }
  };
  const validateFirstName = () => {
    if (fields.firstName.trim().length > 1) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          firstName: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          firstName: {
            status: true,
            text: 'First name should have at least 2 characters.',
          },
        };
      });
    }
  };
  const validateLastName = () => {
    if (fields.lastName.trim().length > 1) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          lastName: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          lastName: {
            status: true,
            text: 'Last name should have at least 2 characters.',
          },
        };
      });
    }
  };
  const validateDob = () => {
    if (fields.dob && fields.dob.trim().length === 10) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          dob: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          dob: {
            status: true,
            text: 'Full Date of Birth is required.',
          },
        };
      });
    }
  };
  const validateCreditCard = (isAmex) => {
    const checkLength = creditCard.ccNumber.replace(/\s/g, '').length;
    if (isAmex ? checkLength === 15 : checkLength === 16) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          ccNumber: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          ccNumber: {
            status: true,
            text: 'Please enter a valid credit card number.',
          },
        };
      });
    }
  };
  const validateExpiration = () => {
    const checkLength = creditCard.expiration.replace(/\s/g, '').length;
    if (checkLength === 5) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          expiration: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          expiration: {
            status: true,
            text: 'Enter the date in MM/YY format',
          },
        };
      });
    }
  };
  const validateSecurityCode = (isAmex) => {
    const checkLength = creditCard.securityCode.replace(/\s/g, '').length;
    if (isAmex ? checkLength === 4 : checkLength === 3) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          securityCode: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          securityCode: {
            status: true,
            text: 'Please enter a valid security code.',
          },
        };
      });
    }
  };
  const validateCardFirstName = () => {
    if (creditCard.cardFName.trim().length > 1) {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          cardFName: {
            status: false,
            text: '',
          },
        };
      });
    } else {
      setFieldsErrors((fieldsErrors) => {
        return {
          ...fieldsErrors,
          cardFName: {
            status: true,
            text: 'First name should have at least 2 characters.',
          },
        };
      });
    }
  };
  const validateShippingAddress = (prop) => {
    if (prop === 'state') {
      if (shippingAddress.state.trim().length === 2) {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressState: {
              status: false,
              text: '',
            },
          };
        });
      } else {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressState: {
              status: true,
              text: "Please enter your state's 2 letter abbreviation",
            },
          };
        });
      }
    }
    if (prop === 'zip') {
      if (shippingAddress.zip.trim().length === 5) {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressZip: {
              status: false,
              text: '',
            },
          };
        });
      } else {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressZip: {
              status: true,
              text: 'Please enter your 5-digit zip code',
            },
          };
        });
      }
    }
    if (prop === 'street') {
      if (shippingAddress.street.trim().length >= 2) {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressStreet: {
              status: false,
              text: '',
            },
          };
        });
      } else {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressStreet: {
              status: true,
              text: 'Please enter a valid street address',
            },
          };
        });
      }
    }
    if (prop === 'city') {
      if (shippingAddress.city.trim().length >= 2) {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressCity: {
              status: false,
              text: '',
            },
          };
        });
      } else {
        setFieldsErrors((fieldsErrors) => {
          return {
            ...fieldsErrors,
            shippingAddressCity: {
              status: true,
              text: 'Please enter a valid city',
            },
          };
        });
      }
    }
  };

  // ASSESSMENT
  const assessment = [
    
    // Login Info Name
    {
      step: (
        <LoginInfoName
          handleTextFieldChange={handleTextFieldChange}
          fieldsErrors={fieldsErrors}
          validateFirstName={validateFirstName}
          validateLastName={validateLastName}
          validatePhone={validatePhone}
          validatePhoneOTP={validatePhoneOTP}
          handleOTPPhoneChange={handleOTPPhoneChange}
          handleResetOTP={handleResetOTP}
          validateEmail={validateEmail}
          fields={fields}
          doctor={doctor}
          verifyOTP={verifyOTP}
          twillioOTP={twillioOTP}
          stripeError={stripeError}
        />
      ),
    },
    // UNC-DEMS instructions
    {
      step: <UncDemsInstructions />,
    },
    // UNC-DEMS first question
    {
      step: <UncDemsFirst handleUncDems={handleUncDems} score={uncDemsScore} fieldsErrors={fieldsErrors} />,
    },
    // UNC-DEMS second question
    {
      step: <UncDemsSecond handleUncDems2={handleUncDems2} score2={uncDemsScore2} fieldsErrors={fieldsErrors} />,
    },
    // Request Prescription
    {
      step: <RequestPrescription productDetails={productDetails} />,
    },
    // Patient History
    {
      step: <PatientHistory handlePatientHistory={handlePatienHistory} patientHistory={patientHistory} />,
    },
    // Other Info
    {
      step: (
        <OtherInfo
          handleGender={handleGender}
          gender={gender}
          handlePregnant={handlePregnant}
          pregnant={pregnant}
          handleSmoker={handleSmoker}
          smoker={smoker}
          handleContactLens={handleContactLens}
          contactLens={contactLens}
          fieldsErrors={fieldsErrors}
        />
      ),
    },
    // Medications
    {
      step: <Medications handleSelection={handleMedicationSelection} medications={medications} />,
    },
    // Allergies
    {
      step: <Allergies handleSelection={handleAllergySelection} allergies={allergies} />,
    },
    // Login Info DOB
    {
      step: <LoginInfoDob handleDateChange={handleDateChange} fieldsErrors={fieldsErrors} fields={fields} />,
    },
    // Review
    {
      step: (
        <Review
          fields={fields}
          uncDemsScore={uncDemsScore}
          uncDemsScore2={uncDemsScore2}
          medications={medications}
          allergies={allergies}
          gender={gender}
          pregnant={pregnant}
          smoker={smoker}
          patientHistory={patientHistory}
          contactLens={contactLens}
          editStep={(activeStepNumber) => editStep(activeStepNumber)}
          handleTextFieldChange={handleTextFieldChange}
          handleDateChange={handleDateChange}
          fieldsErrors={fieldsErrors}
          validateEmail={validateEmail}
          validateFirstName={validateFirstName}
          validateLastName={validateLastName}
          validatePhone={validatePhone}
          handlePatientHistory={handlePatienHistory}
          handleUncTextFieldChange={handleUncTextFieldChange}
          handleUnc2TextFieldChange={handleUnc2TextFieldChange}
          handleMedicationTextFieldChange={handleMedicationTextFieldChange}
          handleSelection={handleMedicationSelection}
          handleAllergyTextFieldChange={handleAllergyTextFieldChange}
          handleAllergySelection={handleAllergySelection} 
          handleGender={handleGender}
          handlePregnant={handlePregnant}
          handleSmoker={handleSmoker}
          handleContactLens={handleContactLens}
          doctor={doctor}
          apiErrorPatient={apiErrorPatient}
        />
      ),
    },
    // Your Prescription
    {
      step: <Prescription stripeError={stripeError}  fields={fields} doctor={doctor} productDetails={productDetails} handleNext={handleNext} />,
    },
    // Shipping Address
    {
      step: (
        <ShippingAddress
          shippingAddress={shippingAddress}
          handleShippingAddressChange={handleShippingAddressChange}
          handleShippingAutoCompleteChange={handleShippingAutoCompleteChange}
          validateShippingAddress={validateShippingAddress}
          fieldsErrors={fieldsErrors}
          apiErrorPatient={apiErrorPatient}
        />
      ),
    },
    // Credit Card
    {
      step: (
          <CreditCard
            creditCard={creditCard}
            fields={fields}
            ccError={ccError}
            fieldsErrors={fieldsErrors}
            handleCreditCardChange={handleCreditCardChange}
            validateSecurityCode={validateSecurityCode}
            validateExpiration={validateExpiration}
            validateCreditCard={validateCreditCard}
            validateCardFirstName={validateCardFirstName}
            subscriptionData={subscriptionData}
            setPaymentIntent={setPaymentIntent}
            setPaymentIntent2={setPaymentIntent2}
            handleBack = {handleBack}
            productDetails={productDetails}
            stripeCustomerId={stripeCustomerId}
            stripePayMethodId={stripePayMethodId}
            shippingAddress={shippingAddress}
            setIsProcess={setIsProcess}
            apiErrorPatient={apiErrorPatient}
          />
      ),
    },
    // Final Screen
    {
      step: <FinalScreen productDetails={productDetails} doctor={doctor} />,
    },
  ];
  const maxSteps = assessment.length;

  // COOKIES
  useEffect(() => {
    document.cookie = `vsnlgyfields = ${JSON.stringify(fields)}; domain=.visionology.com; SameSite=None; Secure`;
  }, [fields]);

  useEffect(() => {
    document.cookie = `vsnlgymedicationsnew = ${JSON.stringify(medications)};domain=.visionology.com; SameSite=None; Secure`;
  }, [medications]);

  useEffect(() => {
    document.cookie = `vsnlgyallergiesnew = ${JSON.stringify(allergies)}; domain=.visionology.com; SameSite=None; Secure`;
  }, [allergies]);

  useEffect(() => {
    document.cookie = `vsnlgygender = ${JSON.stringify(gender)}; domain=.visionology.com; SameSite=None; Secure`;
  }, [gender]);

  useEffect(() => {
    document.cookie = `vsnlgypregnant = ${pregnant}; domain=.visionology.com; SameSite=None; Secure`;
  }, [pregnant]);

  useEffect(() => {
    document.cookie = `vsnlgysmoker = ${smoker}; domain=.visionology.com; SameSite=None; Secure`;
  }, [smoker]);

  useEffect(() => {
    document.cookie = `vsnlgycontactlens = ${contactLens}; domain=.visionology.com; SameSite=None; Secure`;
  }, [contactLens]);

  useEffect(() => {
    document.cookie = `vsnlgypatienthistory = ${JSON.stringify(patientHistory)}; domain=.visionology.com; SameSite=None; Secure`;
  }, [patientHistory]);

  useEffect(() => {
    document.cookie = `vsnlgyuncdems = ${uncDemsScore}; domain=.visionology.com; SameSite=None; Secure`;
  }, [uncDemsScore]);

  useEffect(() => {
    document.cookie = `vsnlgyuncdems2 = ${uncDemsScore2}; domain=.visionology.com; SameSite=None; Secure`;
  }, [uncDemsScore2]);
  
  useEffect(() => {
    if(verifyOTP) {
      sendSMS();
    }
  }, [verifyOTP]);

  useEffect(() => { 
    if(paymentIntent && paymentIntent.status === 'succeeded') {
      const leadData = {
        phone: fields.phoneNumber,
        email: fields.emailAddress,
        patientId: patientId,
        subscriptionId: subscriptionData.subscriptionId,
        authNetIntentId: paymentIntent.id,
        authNetIntentStatus: paymentIntent.status,
        doctorId: doctor.id,
        stripeProductDetails: productDetails
      };
      fetch(`${process.env.REACT_APP_API_URL}/patients/add-patient-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then(({status, json, ok}) => {
        switch (status) {
          case 201:
          case 200:
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            break;
          case 400:
            setApiErrorPatient({
              status: true,
              text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
            });
            break;
          case 500:
          default:
            setApiErrorPatient({
              status: true,
              text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
            });
        }
      });
      setIsProcess(false);
    }
  }, [paymentIntent]);

  useEffect(() => { 
    if(paymentIntent2 && paymentIntent2.status === 'succeeded') {
      const leadData = {
        phone: fields.phoneNumber,
        email: fields.emailAddress,
        patientId: patientId,
        subscriptionId: subscriptionData.subscriptionId,
        authNetIntentId: paymentIntent2.id,
        authNetIntentStatus: paymentIntent2.status,
        doctorId: doctor.id,
        stripeProductDetails: productDetails
      };
      fetch(`${process.env.REACT_APP_API_URL}/patients/add-patient-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      })
      .then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      })
      .then(({status, json, ok}) => {
        switch (status) {
          case 201:
          case 200:
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            break;
          case 400:
            setApiErrorPatient({
              status: true,
              text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
            });
            break;
          case 500:
          default:
            setApiErrorPatient({
              status: true,
              text: "There's a problem evaluating your information. Please try again or contact us at (844) 637 2020.",
            });
        }
      });
      setIsProcess(false);
    }
  }, [paymentIntent2]);

  /*const socketRef = React.createRef();
  const handleMessage = (message) => {
    console.log(message);
  };
  const handleConnect = () => {
    console.log('connected');
    socketRef.current.sendMessage(JSON.stringify({text: 'Hello from the other side!'}));
  };
  const handleDisconnect = () => {
    console.log('disconnected');
  };
  const handleError = () => {
    console.log('error occurred');
  };
  useEffect(() => {
    const data = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      phoneNuber: fields.phoneNumber,
      state: stateOfResidence,
    };
    if (stateOfResidence !== '') {
      socketRef.current.sendMessage(JSON.stringify(data));
    }
  }, [fields.firstName, fields.lastName, fields.phoneNumber, stateOfResidence]);*/

  return (
    <div className={classes.root}>
      {/*<ReactWS
        url='wss://dev.api.visionology.com/ws/dryeyequiz/'
        debug
        onConnect={handleConnect}
        onDisconnect={handleDisconnect}
        reconnectAttempts={3}
        reconnectInterval={5000}
        onError={handleError}
        onMessage={handleMessage}
        reconnect
        ref={socketRef}
      />*/}
      <Paper className={classes.appPaper} elevation={2}>
        <Box p={2}>
          <MobileStepper variant='progress' steps={maxSteps} position='static' activeStep={activeStep} />
          {assessment[activeStep].step}
        </Box>
        {activeStep !== 13 && (
        <div className='makeStyles-buttonsM'>
          {activeStep !== 13 && (
          <Button onClick={() => handleNext()}>
            {activeStep === 12 && 'Next'}
            {activeStep === 14 && 'Go back to the site'}
            {activeStep !== 12 && activeStep !== 14 && 'Next'}
          </Button>
          )}
          {isprocess === true && (
          <div>
            <img src={visionologyLogoMark} alt='Visionology loading' className={classes.spinner1} /> <span className={classes.processing}>Processing...</span>
          </div>
          )}
          {activeStep !== 0 && activeStep !== 13 && activeStep !== 14 && (
            <Button className="back-btn" onClick={() => handleBack()} disabled={activeStep === 0}>
              Back
            </Button>
          )}
        </div>
        )}
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 640,
    margin: '60px auto 0',
    flex: '1 1 auto',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  appPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flex: '0 0 100%',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    maxWidth: 640,
    bottom: 0,
    background: 'rgba(255,168,126,1)',
    zIndex: 999,
    '& .MuiButton-root': {
      height: 48,
      fontSize: '1em',
    },
    '& .MuiButton-root:only-child': {
      margin: '0 auto',
    },
  },
  spinner1: {
    width: 29,
    height: 29,
    animation: 'spin .3s infinite',
    margin: 0,
  },
  processing: {
    verticalAlign: 'top',
  },
}));
