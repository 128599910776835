import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import visionologyIcon from "../../205x80Visionology.png";
import HelpIcon from "@material-ui/icons/Help";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function Header() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.header}>
      <img
        className={classes.visionologyIcon}
        src={visionologyIcon}
        alt="Visionology logo"
      />
      <HelpIcon className={classes.helpIcon} onClick={() => setOpen(true)} />
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="help-modal"
        aria-describedby="help-modal"
      >
        <Box py={4} px={2} className={classes.dialogContent}>
          <Typography component="p" variant="h5">
            If you have any questions or problems, you can contact our customer
            support at
          </Typography>
          <a className={classes.telNumber} href="tel:+18446372020">
            (844) 637 2020
          </a>
        </Box>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  visionologyIcon: {
    height: 60,
    width: 161,
  },
  helpIcon: {
    position: "absolute",
    top: 18,
    right: 10,
    fill: "#6b6b6b",
  },
  dialogContent: {
    textAlign: "center",
  },
  telNumber: {
    display: "inline-block",
    marginTop: "1em",
    background: "rgb(0, 0, 0)",
    border: "1px solid #000",
    fontWeight: "700",
    padding: "1em 2em",
    fontSize: "1.25",
    borderRadius: 50,
    color: "#fff",
    textDecoration: "none",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: "rgb(0, 0, 0)",
      background: "rgba(255, 255, 255, 0.9)",
      boxShadow: "0 0 10px rgba(90, 92, 97, 0.5)",
    },
  },
}));
