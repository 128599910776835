import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { withRouter } from 'react-router-dom';

import {
useStripe,
useElements,
} from '@stripe/react-stripe-js';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
let onceOnlyFlag = true;

const Subscribe2 = (props) => {
const classes = useStyles();
// Get the lookup key for the price from the previous page redirect.
const [messages, _setMessages] = useState('');
// helper for displaying status messages.
const setMessage = (message) => {
_setMessages(`${message}`);
}

// Initialize an instance of stripe.
const stripe = useStripe();
const elements = useElements();

if (!stripe || !elements) {
// Stripe.js has not loaded yet. Make sure to disable
// form submission until Stripe.js has loaded.
return '';
}

// When the subscribe-form is submitted we do a few things:
//
//   1. Tokenize the payment method
//   2. Create the subscription
//   3. Handle any next actions like 3D Secure that are required for SCA.
const handleSubmit2 = async (e) => {
  if(e){
    e.preventDefault();
  }
props.setIsProcess(true);

let name = props.creditCard.cardFName + " " + props.creditCard.cardLName;
name = props.fields.firstName + " " + props.fields.lastName;
//--------
let amount = props.productDetails.unit_amount;
let description = props.productDetails.recurring.interval + " " + props.productDetails.product.name + " dry eye subscription";
let userCustomerId = props.stripeCustomerId;
let userCustomerPayMethodId = props.stripePayMethodId
let error = '';
let paymentIntent2 = null; 

//--------------------------
const billingDetails = {
  name: name,
  address: {
    state: props.shippingAddress.state,
    city: props.shippingAddress.city,
    line1: props.shippingAddress.street + " " + props.shippingAddress.apt,
  },
};
try {
  let intentData = {
    customerId: userCustomerId,
    amount: amount,
    currency: 'USD',
    description: description,
    paymentMethod: userCustomerPayMethodId
  };
  fetch(`${process.env.REACT_APP_API_URL}/stripe/create-payment-intents`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(intentData),
  }).then((response) => {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        })
      )
    );
  }).then(({status, json, ok}) => {
    switch (status) {
      case 201:
      case 200:
        paymentIntent2 = json;
        props.setPaymentIntent2(paymentIntent2);
      break;
      case 400:
        error = json.error.message;
        setMessage(error);
        props.setIsProcess(false);
      break;
      case 500:
      default:
        error = json.error.message;
        setMessage(error);
        props.setIsProcess(false);
    }
  });
} catch (err) {
  console.log(err);
  error = err;
}

if(error) {
  // show error and collect new card details.
  setMessage(error.message);
  props.setIsProcess(false);
  return;
}
//--------------------------
/*
// Use card Element to tokenize payment details
let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
  payment_method: {
    card: elements.getElement(CardNumberElement),
    billing_details: {
      name: name.trim(),
    }
  }
});
*/
}

if(onceOnlyFlag){
  onceOnlyFlag = false;
  handleSubmit2();
}
return (
  <>
  <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box className={classes.questionBox}>
            <Typography variant='h6' component='p' className={classes.fontWeight300}>
              Please wait...
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.secureArea}>
          <Box p={1} pt={2}>
            <Typography variant='body2' component='p' className={classes.body2}>
              You'll be charged <span>${(props.productDetails.unit_amount / 100)} every month</span> thereafter.{' '}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {props.apiErrorPatient.status && (
        <Grid item xs={12}>
          <Box className={classes.section}>
            <span className={classes.error}>{props.apiErrorPatient.text}</span>
          </Box>
        </Grid>
      )}
    </div>
    {
      /*
      <div className="makeStyles-buttonsM" style={{padding: "0 !important"}}>
      <button onClick={handleSubmit2} className="MuiButtonBase-root MuiButton-root MuiButton-text" tabIndex="0" type="button"><span class="MuiButton-label">Pay Now</span><span class="MuiTouchRipple-root"></span></button>
      <button className="back-btn" onClick={() => props.handleBack()}>Back</button>
      </div>
      */
    }
    </>
)
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  questionBox: {
    padding: '16px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  credibilityImage: {
    maxWidth: '100%',
    margin: '8px auto',
  },
  disclaimer: {
    margin: '8px auto',
    maxWidth: 320,
    fontWeight:'300 !important'
  },
  body2: {
    margin: '8px auto',
    maxWidth: 320,
    fontWeight:'400 !important'
  },
  
  fontWeight300: {
    fontWeight:'300 !important'
  },
  secureArea: {
    background: '#eeeeee',
  },
  lockIcon: {
    fill: '#4bc306',
  },
  error: {
    color: 'red',
  },
  revisedPadding: {
    padding: "8px 8px 24px",
  }
}));

export default Subscribe2;