import React, {useEffect, useState, Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputMask from 'react-input-mask';
import Geosuggest from 'react-geosuggest';

export default function LoginInfoName(props) {
  const classes = useStyles();
  const [isSameAddress, setIsSameAddress] = useState(true);

  const handleSameAddress = (event) => {
    setIsSameAddress(event.target.checked);
    if (event.target.checked) {
      props.sameAddressChecked();
    } else {
      props.sameAddressNotChecked();
    }
  };

  const fillInAddress = (gmaps) => {
    if (gmaps) {
      const selectedAddress = gmaps.gmaps.address_components;
      props.handleBillingAutoCompleteChange(selectedAddress);
    }
  };

  useEffect(() => {
    props.sameAddressChecked();
  }, []);

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box className={classes.questionBox}>
            <Typography variant='h5' component='p'>
              Billing Address
            </Typography>
            <Typography className={classes.instructions} variant='body1' component='p'>
              This should match the billing address of your credit card.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox autoFocus color='secondary' checked={isSameAddress} onChange={handleSameAddress} />}
            label='Same as shipping address'
            labelPlacement='end'
          />
        </Grid>
        {!isSameAddress && (
          <Fragment>
            <Grid item xs={12}>
              <Box p={1} className={classes.fields}>
                <Geosuggest
                  placeholder='Start typing your address...'
                  types={['address']}
                  country={['US']}
                  onSuggestSelect={(gmaps) => fillInAddress(gmaps)}
                  highlightMatch={false}
                  inputType='search'
                  autoComplete='off'
                  style={{
                    input: {
                      fontSize: '80%',
                      padding: 16,
                      borderRadius: '50px',
                      background: 'rgba(255, 221, 205,.5)',
                    },
                    suggests: {
                      margin: '0',
                      padding: '0',
                      textAlign: 'left',
                      listStyle: 'none',
                      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                      fontSize: '80%',
                    },
                    suggestItem: {
                      padding: '8px 16px',
                      borderBottom: '1px solid #ddd',
                    },
                  }}
                />
              </Box>
            </Grid>
            {props.billingAddress.street && (
              <Fragment>
                <Grid item xs={12}>
                  <Box p={1} className={classes.fields}>
                    <TextField
                      className='MuiOutlinedInput-inputM'
                      id='street-address-billing'
                      label='Street Address'
                      variant='outlined'
                      color='secondary'
                      value={props.billingAddress.street}
                      onChange={props.handleBillingAddressChange('street')}
                      onBlur={() => props.validateBillingAddress('street')}
                      error={props.fieldsErrors.billingAddressStreet.status}
                      helperText={props.fieldsErrors.billingAddressStreet.text}
                      required
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={1} className={classes.fields}>
                    <TextField
                      className='MuiOutlinedInput-inputM'
                      id='apt-suite-billing'
                      label='Apt/Suite'
                      variant='outlined'
                      color='secondary'
                      value={props.billingAddress.apt}
                      onChange={props.handleBillingAddressChange('apt')}
                      onBlur={() => props.validateBillingAddress('apt')}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={1} className={classes.fields}>
                    <TextField
                      className='MuiOutlinedInput-inputM'
                      id='city-billing'
                      label='City'
                      variant='outlined'
                      color='secondary'
                      value={props.billingAddress.city}
                      onChange={props.handleBillingAddressChange('city')}
                      onBlur={() => props.validateBillingAddress('city')}
                      error={props.fieldsErrors.billingAddressCity.status}
                      helperText={props.fieldsErrors.billingAddressCity.text}
                      required
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={1} className={classes.fields}>
                    <InputMask
                      mask='aa'
                      value={props.billingAddress.state}
                      onChange={props.handleBillingAddressChange('state')}
                      onBlur={() => props.validateBillingAddress('state')}
                      disabled={false}
                      maskChar=' '>
                      {() => (
                        <TextField
                          id='state-billing'
                          label='State'
                          variant='outlined'
                          color='secondary'
                          className={`${classes.stateField} MuiOutlinedInput-inputM`}
                          error={props.fieldsErrors.billingAddressState.status}
                          helperText={props.fieldsErrors.billingAddressState.text}
                          required
                          fullWidth
                        />
                      )}
                    </InputMask>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box p={1} className={classes.fields}>
                    <InputMask
                      mask='99999'
                      value={props.billingAddress.zip}
                      onChange={props.handleBillingAddressChange('zip')}
                      onBlur={() => props.validateBillingAddress('zip')}
                      disabled={false}
                      maskChar=' '>
                      {() => (
                        <TextField
                          className='MuiOutlinedInput-inputM'
                          id='zip-code-billing'
                          label='Zip Code'
                          variant='outlined'
                          color='secondary'
                          error={props.fieldsErrors.billingAddressZip.status}
                          helperText={props.fieldsErrors.billingAddressZip.text}
                          required
                          fullWidth
                        />
                      )}
                    </InputMask>
                  </Box>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  questionBox: {
    padding: '16px 64px 32px',
    maxWidth: 320,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 16px 32px',
    },
  },
  fields: {
    maxWidth: 320,
    margin: '0 auto',
  },
  instructions: {
    paddingTop: 16,
  },
  stateField: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
}));
