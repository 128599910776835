import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import credibility from '../../credibility.jpg';
import LockIcon from '@material-ui/icons/Lock';
import InputMask from 'react-input-mask';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Subscribe from "./Subscribe";
import Subscribe2 from "./Subscribe2";

export default function CreditCard(props) {
  const classes = useStyles();
  const [isAmex, setIsAmex] = useState(false);
  const stripePromise = loadStripe('pk_test_fiPziWx8uIZivfjbE2uhcn7J');

  useEffect(() => {
    if (props.creditCard.ccNumber[0] == 3) {
      setIsAmex(true);
    } else {
      setIsAmex(false);
    }
  }, [props.creditCard.ccNumber[0]]);

  if(props.stripePayMethodId === null)
  {
    return (
      <Elements stripe={stripePromise}><Subscribe {...props}/></Elements>
    );
  }
  else
  {
    return (
      <Elements stripe={stripePromise}><Subscribe2 {...props}/></Elements>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  questionBox: {
    padding: '16px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  credibilityImage: {
    maxWidth: '100%',
    margin: '8px auto',
  },
  disclaimer: {
    margin: '8px auto',
    maxWidth: 320,
  },
  secureArea: {
    background: '#eeeeee',
  },
  lockIcon: {
    fill: '#4bc306',
  },
  error: {
    color: 'red',
  },
  revisedPadding: {
    padding: "8px 8px 24px",
  }
}));
