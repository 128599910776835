import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

const medicationList = [
  'Not taking any medications',
  'Accupril',
  'Aciphex',
  'Actos',
  'Adalat CC',
  'Adderall',
  'Albuterol',
  'Alesse 28',
  'Allegra',
  'Allegra-D',
  'Allopurinol',
  'Alphagan',
  'Alprazolam',
  'Altace',
  'Amaryl',
  'Ambien',
  'Amitriptyline HCL',
  'Amoxicillin Trihydrate',
  'Amoxil',
  'Amphetamine',
  'Amyl nitrite',
  'Anabolic Steroids',
  'Aricept',
  'Atenolol',
  'Atenolol (Mylan)',
  'Atrovent',
  'Augmentin',
  'Avandia',
  'Avapro',
  'Azmacort',
  'Bactroban',
  'Baycol',
  'Biaxin',
  'Buspar',
  'Cardizem CD',
  'Cardura',
  'Carisoprodol',
  'Ceftin',
  'Cefzil',
  'Celebrex',
  'Celexa',
  'Cephalexin',
  'Cipro',
  'Claritin',
  'Claritin Reditabs',
  'Claritin-D 12HR',
  'Claritin-D 24HR',
  'Climara',
  'Clonazepam',
  'Clonidine HCL',
  'Cocaine',
  'Combivent',
  'Coumadin',
  'Cozaar',
  'Cyclobenzaprine',
  'Depakote',
  'Detrol',
  'Diazepam',
  'Diflucan',
  'Dilantin',
  'Diovan',
  'Diovan HCT',
  'Effexor XR',
  'Elocon',
  'Ery-Tab',
  'Evista',
  'Flomax',
  'Flonase',
  'Flovent',
  'Folic Acid',
  'Fosamax',
  'Furosemide',
  'Gemfibrozil',
  'Glucophage',
  'Glucotrol XL',
  'Glyburide',
  'Hydrochlorothiazide',
  'Hydrocodone',
  'Hyzaar',
  'Ibuprofen',
  'Imitrex',
  'Isosorbide Mononitrate',
  'K-Dur',
  'Ketamine',
  'Klor-Con 10',
  'Lanoxin',
  'Lescol',
  'Levaquin',
  'Levothroid',
  'Levoxyl',
  'Lipitor',
  'Lo Ovral',
  'Loestrin FE',
  'Lorazepam',
  'Lotensin',
  'Lotrel',
  'Lotrisone',
  'Lysergic Acid Diethylamide',
  'Macrobid',
  'Marijuana',
  'MDMA',
  'Medroxyprogesterone',
  'Mescaline',
  'Methamphetamine',
  'Methylphenidate',
  'Methylprednisolone',
  'Metoprolol Tartrate',
  'Miacalcin',
  'Monopril',
  'Morphine',
  'Naproxen',
  'Naproxen-Sodium',
  'Nasonex',
  'Neurontin',
  'Nicotine',
  'Norvasc',
  'Ortho-Cyclen',
  'Ortho-Tri-Cyclen',
  'Oxycodone',
  'OxyContin',
  'Paxil',
  'Penicillin VK',
  'Pepcid',
  'Phenergan',
  'Plavix',
  'Plendil',
  'Potassium chloride',
  'Pravachol',
  'Prednisone',
  'Premarin',
  'Prempro',
  'Prevacid',
  'Prilosec',
  'Prinivil',
  'Procardia-XL',
  'Promethazine',
  'Propoxyphene',
  'Proventil HFA',
  'Prozac',
  'Psilocybin',
  'Ranitidine',
  'Relafen',
  'Remeron',
  'Risperdal',
  'Ritalin',
  'Rohypnol',
  'Roxicet',
  'Serevent',
  'Serzone',
  'Singulair',
  'Synthroid',
  'Tamoxifen Citrate',
  'Temazepam',
  'Tiazac',
  'Tobradex',
  'Toprol XL',
  'Trazodone',
  'Triamterene',
  'Trimox',
  'Triphasil',
  'Ultram',
  'Valtrex',
  'Vasotec',
  'Veetids',
  'Verapamil',
  'Viagra',
  'Vicoprofen',
  'Vioxx',
  'Warfarin Sodium',
  'Wellbutrin SR',
  'Xalatan',
  'Xenical',
  'Zestoretic',
  'Zestril',
  'Ziac',
  'Zithromax',
  'Zithromax Z-PAK',
  'Zocor',
  'Zoloft',
  'Zyprexa',
  'Zyrtec',
];

export default function Medications(props) {
  const classes = useStyles();
  const medications = props.medications;
  const [notTakingAnyMedications, setNotTakingAnyMedications] = useState(false);

  const handleNotTakingAnyMedications = (event) => {
    setNotTakingAnyMedications(event.target.checked);
    if (event.target.checked) {
      props.handleSelection(event, ['Not taking any medications']);
    } else {
      props.handleSelection(event, []);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p'>
            Please select any medications you are currently taking.
          </Typography>
          <br />
          <Typography variant='h5' component='p'>
            If your medication is not listed, you can type it and press enter.
          </Typography>
          <Box pt={4}>
            <FormControlLabel
              className="checkboxlabel"
              control={<Checkbox color='secondary' checked={notTakingAnyMedications} onChange={handleNotTakingAnyMedications} />}
              label="I'm not currently taking any medications."
              labelPlacement='end'
            />
          </Box>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id='medication-selectbox'
              className={classes.selectBox}
              disabled={notTakingAnyMedications}
              freeSolo
              filterSelectedOptions
              multiple
              value={medications}
              onChange={(event, value) => props.handleSelection(event, value)}
              options={medicationList}
              renderOption={(medication) => medication}
              getOptionLabel={(medication) => medication}
              renderInput={(params) => (
                <TextField {...params} variant='outlined' placeholder='Select from the list or start typing...' margin='normal' fullWidth autoFocus />
              )}
            />
          </FormControl>
        </Box>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    minWidth: '100%',
  },
  questionBox: {
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  helperText: {
    marginTop: '1em',
  },
  
}));
