import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { withRouter } from 'react-router-dom';

import {
CardElement,
PaymentIntents,
CardNumberElement,
CardExpiryElement,
CardCvcElement,
useStripe,
useElements,
} from '@stripe/react-stripe-js';

import { Redirect } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import credibility from '../../credibility.jpg';
import LockIcon from '@material-ui/icons/Lock';
import InputMask from 'react-input-mask';
import StripeInput from './stripeInput'

const Subscribe = (props) => {
const classes = useStyles();
// Get the lookup key for the price from the previous page redirect.
const [clientSecret] = useState(props.subscriptionData.clientSecret);
const [subscriptionId] = useState(props.subscriptionData.subscriptionId);
const [name, setName] = useState('Jasvir Singh');
const [messages, _setMessages] = useState('');
// helper for displaying status messages.
const setMessage = (message) => {
_setMessages(`${message}`);
}

// Initialize an instance of stripe.
const stripe = useStripe();
const elements = useElements();

if (!stripe || !elements) {
// Stripe.js has not loaded yet. Make sure to disable
// form submission until Stripe.js has loaded.
return '';
}

// When the subscribe-form is submitted we do a few things:
//
//   1. Tokenize the payment method
//   2. Create the subscription
//   3. Handle any next actions like 3D Secure that are required for SCA.
const handleSubmit = async (e) => {
e.preventDefault();
props.setIsProcess(true);

let name = props.creditCard.cardFName + " " + props.creditCard.cardLName;
name = props.fields.firstName + " " + props.fields.lastName;
//--------
let amount = props.productDetails.unit_amount;
let currency = props.productDetails.unit_amount;
let description = props.productDetails.recurring.interval + " " + props.productDetails.product.name + " dry eye subscription";
let userCustomerId = props.stripeCustomerId;
let error = '';
let paymentIntent = null; 

//--------------------------
const billingDetails = {
  name: name,
  address: {
    state: props.shippingAddress.state,
    city: props.shippingAddress.city,
    line1: props.shippingAddress.street + " " + props.shippingAddress.apt,
  },
};
try {
  await stripe
    .createPaymentMethod({
      type: "card",
      billing_details: billingDetails,
      card: elements.getElement(CardNumberElement),
    })
    .then((resp) => {
      let attachData = {
        customerId: userCustomerId,
        paymentMethod: resp.paymentMethod,
      };
      fetch(`${process.env.REACT_APP_API_URL}/stripe/attach-payment-method`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(attachData),
      }).then((response) => {
        return new Promise((resolve) =>
          response.json().then((json) =>
            resolve({
              status: response.status,
              ok: response.ok,
              json,
            })
          )
        );
      }).then(({status, json, ok}) => {
        switch (status) {
          case 201:
          case 200:
            let paymentAttach = json;
            
            let intentData = {
              customerId: userCustomerId,
              amount: amount,
              currency: 'USD',
              description: description,
              paymentMethod: paymentAttach.id
            };
            fetch(`${process.env.REACT_APP_API_URL}/stripe/create-payment-intents`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(intentData),
            }).then((response) => {
              return new Promise((resolve) =>
                response.json().then((json) =>
                  resolve({
                    status: response.status,
                    ok: response.ok,
                    json,
                  })
                )
              );
            }).then(({status, json, ok}) => {
              switch (status) {
                case 201:
                case 200:
                  paymentIntent = json;
                  props.setPaymentIntent(paymentIntent);
                break;
                case 400:
                  error = json.error.message;
                  setMessage(error);
                  props.setIsProcess(false);
                break;
                case 500:
                default:
                  error = json.error.message;
                  setMessage(error);
                  props.setIsProcess(false);
              }
            });

          break;
          case 400:
            error = json.error.message;
            setMessage(error);
            props.setIsProcess(false);
          break;
          case 500:
          default:
            error = json.error.message;
            setMessage(error);
            props.setIsProcess(false);
        }
      });
      
    });
} catch (err) {
  console.log(err);
  error = err;
}

if(error) {
  // show error and collect new card details.
  setMessage(error.message);
  props.setIsProcess(false);
  return;
}
//--------------------------
/*
// Use card Element to tokenize payment details
let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
  payment_method: {
    card: elements.getElement(CardNumberElement),
    billing_details: {
      name: name.trim(),
    }
  }
});
*/
}

return (
  <>
  <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box className={classes.questionBox}>
            <Typography variant='h6' component='p' className={classes.fontWeight300}>
              You’re one click away.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.secureArea}>
          <Box p={1} pt={2}>
            <LockIcon className={classes.lockIcon} />
            { /* <Typography variant='body2' component='p' className={classes.body2}>
              You'll be charged <span>$1 </span> for your first 30-day supply, then <span>${(props.productDetails.unit_amount / 100)} every month</span> thereafter.{' '}
              </Typography> */ }
            <Typography variant='body2' component='p' className={classes.body2}>
              You'll be charged <span>${(props.productDetails.unit_amount / 100)} every month</span> thereafter.{' '}
            </Typography>
          </Box>
          {props.ccError !== '' && (
            <Typography variant='body1' component='p'>
              <span className={classes.error}>{props.ccError}</span>
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} className={classes.secureArea}>
          <Box p={1} pb={3}>
            <TextField
                className='MuiOutlinedInput-inputM'
                id='creditCardFirstName'
                label='Full Name'
                variant='outlined'
                color='secondary'
                error={props.fieldsErrors.cardFName.status}
                helperText={props.fieldsErrors.cardFName.text}
                required
                fullWidth
                value={props.fields.firstName}
                onChange={props.handleCreditCardChange('cardFName')}
                onBlur={() => props.validateCardFirstName()}
                disabled={false}
            />
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.secureArea}>
          <Box p={1} pb={3} className={classes.revisedPadding}>
          <TextField
                className='MuiOutlinedInput-inputM'
                id='creditCardLastName'
                label='Last Name'
                variant='outlined'
                color='secondary'
                fullWidth
                value={props.fields.lastName}
                onChange={props.handleCreditCardChange('cardLName')}
                disabled={false}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.secureArea}>
            
          <Box p={1}>
            <InputMask
              mask={false ? '9999 999999 99999' : '9999 9999 9999 9999'}
              //name="ccnumber"
              value={props.creditCard.ccNumber}
              //onChange={props.handleCreditCardChange('ccNumber')}
              //onBlur={() => props.validateCreditCard(false)}
              disabled={false}
              maskChar=' '>
              {() => (
                <TextField
                  className='MuiOutlinedInput-inputM'
                  id='creditCardNumber'
                  //label='Credit Card Number'
                  variant='outlined'
                  color='secondary'
                  //error={props.fieldsErrors.ccNumber.status}
                  //helperText={props.fieldsErrors.ccNumber.text}
                  required
                  fullWidth
                  autoFocus
                  InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                          component: CardNumberElement
                      },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </InputMask>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.secureArea}>
          <Box p={1} pb={3}>
            <InputMask
              mask='99/99'
              //name="ccexp"
              value={props.creditCard.expiration}
              //onChange={props.handleCreditCardChange('expiration')}
              //onBlur={() => props.validateExpiration()}
              disabled={false}
              maskChar=' '>
              {() => (
                <TextField
                  className='MuiOutlinedInput-inputM'
                  id='creditCardExpiration'
                  //label='Expiration (mm/yy)'
                  variant='outlined'
                  color='secondary'
                  //error={props.fieldsErrors.expiration.status}
                  //helperText={props.fieldsErrors.expiration.text}
                  required
                  fullWidth
                  InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                          component: CardExpiryElement
                      },
                      
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </InputMask>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.secureArea}>
          <Box p={1} pb={3}>
            <InputMask
              mask={false ? '9999' : '999'}
              //name="cvc"
              value={props.creditCard.securityCode}
              //onChange={props.handleCreditCardChange('securityCode')}
              //onBlur={() => props.validateSecurityCode(false)}
              disabled={false}
              maskChar=' '>
              {() => (
                <TextField
                  className='MuiOutlinedInput-inputM'
                  id='creditCardSecurityCode'
                  //label='Security Code'
                  variant='outlined'
                  color='secondary'
                  //error={props.fieldsErrors.securityCode.status}
                  //helperText={props.fieldsErrors.securityCode.text}
                  required
                  fullWidth
                  InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardCvcElement
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </InputMask>
          </Box>
        </Grid>
        <div className='error'>{messages}</div>
        <Grid item xs={12}>
          <img src={credibility} alt='Secure Visionology transaction' className={classes.credibilityImage} />
        </Grid>
        <Typography variant='h6' component='p' className={classes.disclaimer}>
          Cancel anytime.<br/>100% money back guarantee.
        </Typography>
      </Grid>
      {props.apiErrorPatient.status && (
        <Grid item xs={12}>
          <Box className={classes.section}>
            <span className={classes.error}>{props.apiErrorPatient.text}</span>
          </Box>
        </Grid>
      )}
    </div>

    <div class="makeStyles-buttonsM" style={{padding: "0 !important"}}>
      <button onClick={handleSubmit} class="MuiButtonBase-root MuiButton-root MuiButton-text" tabindex="0" type="button"><span class="MuiButton-label">Pay Now</span><span class="MuiTouchRipple-root"></span></button>
      <button className="back-btn" onClick={() => props.handleBack()}>Back</button>
    </div> 
    </>
)
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  questionBox: {
    padding: '16px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  credibilityImage: {
    maxWidth: '100%',
    margin: '8px auto',
  },
  disclaimer: {
    margin: '8px auto',
    maxWidth: 320,
    fontWeight:'300 !important'
  },
  body2: {
    margin: '8px auto',
    maxWidth: 320,
    fontWeight:'400 !important'
  },
  
  fontWeight300: {
    fontWeight:'300 !important'
  },
  secureArea: {
    background: '#eeeeee',
  },
  lockIcon: {
    fill: '#4bc306',
  },
  error: {
    color: 'red',
  },
  revisedPadding: {
    padding: "8px 8px 24px",
  }
}));

export default Subscribe;