import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import Assessment from "./components/assessment";
import Help from "./components/help";
import Header from "./components/header";

export default function App() {
  const classes = useStyles();

  return (
    <Router>
      <Switch>
        <Route path="/">
          <div className={classes.main}>
            <Header />
            <Assessment />
            <Help />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "100vh",
  },
}));
