import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import hipaaIcon from "../../hipaa.png";
import legitScriptIcon from "../../legitscript.png";

export default function Help() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.smaller} variant="h6" component="p">
        Your answers are saved securely according to HIPAA and federal law.
      </Typography>
      <div className={classes.hipaaContainer}>
        <img className={classes.hipaaIcon} src={hipaaIcon} alt="hipaa logo" />
        <img
          className={classes.legitScriptIcon}
          src={legitScriptIcon}
          alt="LegitScript logo"
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: "1em",
    width: "100%",
  },
  hipaaContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  hipaaIcon: {
    height: 60,
    width: 110,
    marginRight: 32,
    marginTop: 10,
  },
  legitScriptIcon: {
    height: 80,
    width: 107,
  },
  smaller: {
    fontSize: "1rem !important",
    padding: "0 5%",
  },
}));
