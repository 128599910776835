import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffa87e',
      selected: '#819896',
    },
    secondary: {
      main: '#819896',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 16,
        fontWeight: 700,
        lineHeight: 1.3,
        letterSpacing: 0,
      },
      text: {
        borderRadius: 2,
      },
      textSizeSmall: {
        padding: '4px 12px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        right: 0,
      },
    },
    MuiMobileStepper: {
      progress: {
        width: '100%',
      },
    },
    MuiLinearProgress: {
      root: {
        height: 12,
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0 9px',
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
