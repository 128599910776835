import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function OtherInfo(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
      <Box className={classes.questionBox}>
          <Typography variant='h5' component='p'>
          </Typography>
        </Box>
        <Box className="makeStyles-diseaseBoxM">
          <Grid component='label' container alignItems='center'>
            <Grid item xs={7}>
              <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                Please select your gender
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid component='label' container alignItems='center' justify='center'>
                <Grid item>
                <div className={classes.buttonContainer}>
                  <Box p={1}>
                    <Button variant={props.gender.female} color='secondary' onClick={props.handleGender('female')}>
                      Female
                    </Button>
                  </Box>
                  <Box p={1}>
                    <Button variant={props.gender.male} color='secondary' onClick={props.handleGender('male')}>
                      Male
                    </Button>
                  </Box>
                </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        
        {props.fieldsErrors.gender.status && (
          <Typography variant='body2' component='p' className={classes.genderError}>
            {props.fieldsErrors.gender.text}
          </Typography>
        )}
        {props.gender.female === 'contained' && (
          <>
            <Box className="makeStyles-diseaseBoxM">
              <Grid component='label' container alignItems='center'>
                <Grid item xs={8}>
                  <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                    Are you pregnant or breastfeeding?
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Grid component='label' container alignItems='center' justify='center'>
                    <Grid item>
                      <RadioGroup
                        className={classes.horizontal}
                        aria-label='pregnant'
                        name='pregnant'
                        value={props.pregnant === true ? 'true' : 'false'}
                        onChange={props.handlePregnant()}>
                        <FormControlLabel className={props.pregnant === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                        <FormControlLabel className={props.pregnant === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Box className="makeStyles-diseaseBoxM">
        <Grid component='label' container alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                Do you smoke?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid component='label' container alignItems='center' justify='center'>
                <Grid item>
                  <RadioGroup
                    className={classes.horizontal}
                    aria-label='smoker'
                    name='smoker'
                    value={props.smoker === true ? 'true' : 'false'}
                    onChange={props.handleSmoker()}>
                    <FormControlLabel className={props.smoker === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                    <FormControlLabel className={props.smoker === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className="makeStyles-diseaseBoxM">
          <Grid component='label' container alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h6' component='p' className={classes.diseaseLabel}>
                Do you wear contact lenses?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid component='label' container alignItems='center' justify='center'>
                <Grid item>
                  <RadioGroup
                    className={classes.horizontal}
                    aria-label='contact lenses'
                    name='contactLens'
                    value={props.contactLens === true ? 'true' : 'false'}
                    onChange={props.handleContactLens()}>
                    <FormControlLabel className={props.contactLens === true ? 'Yes' : 'No'} value='true' control={<Radio />} label='Yes' />
                    <FormControlLabel className={props.contactLens === true ? 'No' : 'Yes'} value='false' control={<Radio />} label='No' />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
       
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  questionBox: {
    width: '100%',
    padding: '16px 16px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  diseaseLabel: {
    fontSize: '125%',
    lineHeight: "1.3rem",
    fontSize: "1.2rem !important",
    lineHeight: "1.8rem !important",
    color: "#001141 !important",
    fontWeight: "normal !important",
    textAlign: "left !important",
    padding:"0 16px !important",
    margin: "0 !important",
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  horizontal: {
    flexDirection: 'row',
  },
  genderError: {
    color: 'red !important',
    textAlign: 'center',
    width: '100%',
    marginBottom: '10px !important',
  },
}));
