import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import IconButton from '@material-ui/core/IconButton';

const YearDialog = (props) => {
  const classes = useStyles();
  const {handleYearChange, popperOpen, decade} = props;
  return (
    <Dialog className={classes.popperZ} open={popperOpen.year}>
      <Paper className={classes.popperPaper}>
        <Typography className={classes.calendarLabel} variant='body1'>
          Select your birth year
        </Typography>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}0`)}>
          {`${decade}0`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}1`)}>
          {`${decade}1`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}2`)}>
          {`${decade}2`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}3`)}>
          {`${decade}3`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}4`)}>
          {`${decade}4`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}5`)}>
          {`${decade}5`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}6`)}>
          {`${decade}6`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}7`)}>
          {`${decade}7`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}8`)}>
          {`${decade}8`}
        </Button>
        <Button className={classes.popperMonthButton} onClick={() => handleYearChange(`${decade}9`)}>
          {`${decade}9`}
        </Button>
      </Paper>
    </Dialog>
  );
};

export default function LoginInfoDob(props) {
  const classes = useStyles();
  const [dob, setDob] = useState({
    month: props.fields.dob !== null ? props.fields.dob.substring(0, 2) : '',
    day: props.fields.dob !== null ? props.fields.dob.substring(3, 5) : '',
    year: props.fields.dob !== null ? props.fields.dob.substring(6, 10) : '',
    decade: '',
  });
  const [popperOpen, setPopperOpen] = useState({
    month: false,
    day: false,
    year: false,
    decade: false,
  });

  const handleDobTextChange = (prop) => (event) => {
    setDob({...dob, [prop]: event.target.value});
  };

  const handleMonthChange = (value) => {
    setDob({...dob, month: value});
    handleClose('month');
  };

  const handleDayChange = (value) => {
    setDob({...dob, day: value});
    handleClose('day');
  };

  const handleDecadeChange = (value) => {
    setDob({...dob, decade: value});
    setPopperOpen({...popperOpen, decade: false, year: true});
  };

  const handleYearChange = (value) => {
    setDob({...dob, year: value});
    handleClose('year');
  };

  const handlePopperOpen = (prop) => {
    setPopperOpen({...popperOpen, [prop]: true});
  };

  const handleClose = (prop) => {
    setPopperOpen({...popperOpen, [prop]: false});
  };

  useEffect(() => {
    props.handleDateChange(`${dob.year}/${dob.month}/${dob.day}`);
  }, [dob]);

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p'>
            We also need your date of birth for our patient records.
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Box p={1} className={classes.field}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  id='dob-month'
                  className={classes.dobField}
                  variant='outlined'
                  color='secondary'
                  value={dob.month}
                  placeholder='MM'
                  onChange={handleDobTextChange('month')}
                  required
                  fullWidth
                  inputProps={{maxLength: 2}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton className={classes.calendarIcon} aria-label='toggle password visibility' onClick={() => handlePopperOpen('month')}>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={props.fieldsErrors.dob.status}
                  autoFocus
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id='dob-day'
                  className={classes.dobField}
                  variant='outlined'
                  color='secondary'
                  value={dob.day}
                  placeholder='DD'
                  onChange={handleDobTextChange('day')}
                  required
                  fullWidth
                  inputProps={{maxLength: 2}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton className={classes.calendarIcon} aria-label='toggle password visibility' onClick={() => handlePopperOpen('day')}>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={props.fieldsErrors.dob.status}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id='dob-year2'
                  className={classes.dobFieldYear}
                  variant='outlined'
                  color='secondary'
                  value={dob.year}
                  placeholder='YYYY'
                  onChange={handleDobTextChange('year')}
                  required
                  fullWidth
                  inputProps={{maxLength: 4}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton className={classes.calendarIcon} aria-label='toggle password visibility' onClick={() => handlePopperOpen('decade')}>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={props.fieldsErrors.dob.status}
                />
              </Grid>
            </Grid>
            {props.fieldsErrors.dob.text && (
              <Typography className='error' variant='body2' component='p'>
                {props.fieldsErrors.dob.text}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog className={classes.popperZ} open={popperOpen.month} onClose={() => handleClose('month')}>
        <Paper className={classes.popperPaper}>
          <Typography className={classes.calendarLabel} variant='body1'>
            Select your birth month
          </Typography>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('01')}>
            Jan
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('02')}>
            Feb
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('03')}>
            Mar
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('04')}>
            Apr
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('05')}>
            May
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('06')}>
            Jun
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('07')}>
            Jul
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('08')}>
            Aug
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('09')}>
            Sep
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('10')}>
            Oct
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('11')}>
            Nov
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleMonthChange('12')}>
            Dec
          </Button>
        </Paper>
      </Dialog>
      <Dialog className={classes.popperZ} open={popperOpen.day} onClose={() => handleClose('day')}>
        <Paper className={classes.popperPaperDay}>
          <Typography className={classes.calendarLabel} variant='body1'>
            Select your birth day
          </Typography>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('01')}>
            1
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('02')}>
            2
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('03')}>
            3
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('04')}>
            4
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('05')}>
            5
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('06')}>
            6
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('07')}>
            7
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('08')}>
            8
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('09')}>
            9
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('10')}>
            10
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('11')}>
            11
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('12')}>
            12
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('13')}>
            13
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('14')}>
            14
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('15')}>
            15
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('16')}>
            16
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('17')}>
            17
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('18')}>
            18
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('19')}>
            19
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('20')}>
            20
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('21')}>
            21
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('22')}>
            22
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('23')}>
            23
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('24')}>
            24
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('25')}>
            25
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('26')}>
            26
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('27')}>
            27
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('28')}>
            28
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('29')}>
            29
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('30')}>
            30
          </Button>
          <Button className={classes.popperDayButton} onClick={() => handleDayChange('31')}>
            31
          </Button>
        </Paper>
      </Dialog>
      <Dialog className={classes.popperZ} open={popperOpen.decade} onClose={() => handleClose('decade')}>
        <Paper className={classes.popperPaper}>
          <Typography className={classes.calendarLabel} variant='body1'>
            Select decade first
          </Typography>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('190')}>
            1900-09
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('191')}>
            1910-19
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('192')}>
            1920-29
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('193')}>
            1930-39
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('194')}>
            1940-49
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('195')}>
            1950-59
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('196')}>
            1960-69
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('197')}>
            1970-79
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('198')}>
            1980-89
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('199')}>
            1990-99
          </Button>
          <Button className={classes.popperMonthButton} onClick={() => handleDecadeChange('200')}>
            2000-09
          </Button>
        </Paper>
      </Dialog>
      <YearDialog decade={dob.decade} handleYearChange={handleYearChange} popperOpen={popperOpen} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  consentCheckbox: {
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  questionBox: {
    padding: '16px 64px',
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  dobLabel: {
    fontSize: '.75rem',
    textAlign: 'left',
    paddingLeft: 16,
    paddingBottom: 8,
    marginTop: '-6px',
  },
  dobField: {
    '& input': {
      padding: '11.5px 14px',
    },
  },
  dobFieldYear: {
    '& input': {
      padding: '11.5px 4px',
    },
  },
  popperZ: {
    zIndex: 1001,
  },
  popperPaper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 240,
  },
  popperPaperDay: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 238,
  },
  popperMonthButton: {
    width: 80,
    padding: '16px 0',
    textAlign: 'center',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  popperDayButton: {
    width: 34,
    flexBasis: 34,
    minWidth: 0,
    padding: '8px 0',
    textAlign: 'center',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  calendarIcon: {
    position: 'absolute',
    right: 0,
    background: 'rgba(225,168,126,.5)',
    borderRadius: '0 4px 4px 0',
    padding: '9px',

    borderRadius: '0 50px 50px 0',
    background: '#eb434f !important',
    color: '#FFF',
    right: 0,
    padding: '9px',
  },
  calendarLabel: {
    flexBasis: '100%',
    padding: 8,
    borderBottom: '1px solid #ddd',
    textAlign: 'center',
    background: '#eee',
  },
  field: {
    maxWidth: 320,
    margin: '0 auto',
  },
}));
