import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputMask from 'react-input-mask';

export default function LoginInfoName(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      {props.verifyOTP ? (
        <Grid container spacing={0}>
        <Box className={classes.fullWidth}>
          <Typography variant='h5' component='p'>
            Verify OTP
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Box p={1} className={classes.field}>
            <InputMask
              mask='99999'
              value={props.fields.phoneOTP}
              onChange={props.handleTextFieldChange('phoneOTP')}
              onBlur={() => props.validatePhoneOTP()}
              disabled={false}
              maskChar=' '>
              {() => (
                <TextField
                  className='MuiOutlinedInput-inputM'
                  id='phone-otp'
                  label='OTP'
                  variant='outlined'
                  color='secondary'
                  autoComplete='chrome-off'
                  error={props.fieldsErrors.phoneOTP.status}
                  helperText={props.fieldsErrors.phoneOTP.text}
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Box>
          <Box p={1} className={classes.field}>
            <Typography variant='body1' component='p' className={classes.leftTag} onClick={props.handleOTPPhoneChange()}> 
              Change Phone Number
            </Typography>
            <Typography variant='body1' component='p' className={classes.rightTag} onClick={props.handleResetOTP()}>
              Resend OTP ({props.twillioOTP})
            </Typography>
          </Box>
          
        </Grid>
      </Grid>
      ) : (
        <Grid container spacing={0}>
          <Box className={classes.questionBox}>
            <Typography variant='h5' component='p'>
              Let's get started with some information that is going to need for your prescription.
            </Typography>
          </Box>
          <Grid item xs={12}>
            <Box p={1} className={classes.field}>
              <TextField
                className='MuiOutlinedInput-inputM'
                id='first-name'
                label='First name'
                variant='outlined'
                color='secondary'
                autoComplete='chrome-off'
                value={props.fields.firstName}
                onChange={props.handleTextFieldChange('firstName')}
                onBlur={() => props.validateFirstName()}
                error={props.fieldsErrors.firstName.status}
                helperText={props.fieldsErrors.firstName.text}
                required
                fullWidth
                //autoFocus
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={1} className={classes.field}>
              <TextField
                className='MuiOutlinedInput-inputM'
                id='last-name'
                label='Last name'
                variant='outlined'
                color='secondary'
                autoComplete='chrome-off'
                value={props.fields.lastName}
                onChange={props.handleTextFieldChange('lastName')}
                onBlur={() => props.validateLastName()}
                error={props.fieldsErrors.lastName.status}
                helperText={props.fieldsErrors.lastName.text}
                required
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={1} className={classes.field}>
              <InputMask
                mask='(999) 999 9999'
                value={props.fields.phoneNumber}
                onChange={props.handleTextFieldChange('phoneNumber')}
                onBlur={() => props.validatePhone()}
                disabled={false}
                maskChar=' '>
                {() => (
                  <TextField
                    className='MuiOutlinedInput-inputM'
                    id='phone-number'
                    label='Phone number'
                    variant='outlined'
                    color='secondary'
                    autoComplete='chrome-off'
                    error={props.fieldsErrors.phoneNumber.status}
                    helperText={props.fieldsErrors.phoneNumber.text}
                    required
                    fullWidth
                  />
                )}
              </InputMask>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={1} className={classes.field}>
              <TextField
                className='MuiOutlinedInput-inputM'
                id='email-address'
                label='Email Address'
                variant='outlined'
                color='secondary'
                value={props.fields.emailAddress}
                onChange={props.handleTextFieldChange('emailAddress')}
                onBlur={() => props.validateEmail()}
                error={props.fieldsErrors.emailAddress.status}
                helperText={props.fieldsErrors.emailAddress.text}
                required
                fullWidth
              />
            </Box>
          </Grid>
          {props.stripeError !== '' && (
            <Typography className='error' variant='body2' component='p'>
              <span className={classes.error}>{props.stripeError}</span>
            </Typography>
          )}
        </Grid>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  questionBox: {
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  field: {
    maxWidth: 320,
    margin: '0 auto',
  },
  fullWidth: {
    width: "100%",
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  leftTag: {
    display: "inline-block",
    textAlign: "left",
    fontSize: "14px",
    width: "50%",
    fontWeight: "500 !important",
    letterSpacing: "0.0075em !important",
    color: "#27345e !important",
    fontFamily: "Jost !important",
    cursor: "pointer",
    paddingTop: "0",
  },
  rightTag: {
    display: "inline-block",
    textAlign: "right",
    fontSize: "14px",
    width: "50%",
    fontWeight: "500 !important",
    letterSpacing: "0.0075em !important",
    color: "#27345e !important",
    fontFamily: "Jost !important",
    cursor: "pointer",
    paddingTop: "0",
  }
}));
