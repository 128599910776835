import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import visionologyLogoMark from "../../visionology-logo-mark.svg";

export default function Evaluation(props) {
  const classes = useStyles();
  const [endMessage, setEndMessage] = useState("assessment");

  useEffect(() => {
    setTimeout(function () {
      setEndMessage("medications");
    }, 1500);
    setTimeout(function () {
      setEndMessage("allergies");
    }, 3000);
    setTimeout(function () {
      setEndMessage("history");
    }, 5200);
    setTimeout(function () {
      props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 8000);
  }, []);

  return (
    <Grid container spacing={0}>
      <Box className={classes.endBox}>
        {endMessage === "assessment" && (
          <Typography variant="h5" component="p" className="fadeInUp">
            Calculating your dry eye assessment results.
          </Typography>
        )}
        {endMessage === "medications" && (
          <Typography variant="h5" component="p" className="fadeInUp">
            Analyzing your medications.
          </Typography>
        )}
        {endMessage === "allergies" && (
          <Typography variant="h5" component="p" className="fadeInUp">
            Checking out your allergies.
          </Typography>
        )}
        {endMessage === "history" && (
          <Typography variant="h5" component="p" className="fadeInUp">
            Evaluating your eye history.
          </Typography>
        )}
        <img
          src={visionologyLogoMark}
          alt="Visionology loading"
          className={classes.spinner}
        />
      </Box>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  endBox: {
    width: "100%",
    padding: "16px 16px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  spinner: {
    width: 60,
    height: 60,
    animation: "spin .3s infinite",
    margin: 30,
  },
}));
