import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function StateSelector(props) {
  const classes = useStyles();
  const activeStates = ['AL', 'CA', 'FL', 'GA', 'TN'];
  const renderLongName = () => {
    switch (props.stateOfResidence) {
      case 'AL':
        return 'Alabama';
      case 'CA':
        return 'California';
      case 'FL':
        return 'Florida';
      case 'GA':
        return 'Georgia';
      case 'TN':
        return 'Tennessee';
      default:
        return '';
    }
  };

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p'>
            Please click below <ArrowDownwardIcon /> <br /> to select your state of residence:
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Box p={1}>
            <FormControl className={classes.formControl} variant='outlined'>
              <Select
                native
                value={props.stateOfResidence}
                onChange={props.handleStateSelection}
                error={props.fieldsErrors.stateSelection.status}
                inputProps={{
                  name: 'stateOfResidence',
                  id: 'stateOfResidence',
                }}>
                <option aria-label='None' value=''>
                  Select your state...
                </option>
                <option value='AL'>Alabama</option>
                <option value='AK'>Alaska</option>
                <option value='AZ'>Arizona</option>
                <option value='AR'>Arkansas</option>
                <option value='CA'>California</option>
                <option value='CO'>Colorado</option>
                <option value='CT'>Connecticut</option>
                <option value='DE'>Delaware</option>
                <option value='FL'>Florida</option>
                <option value='GA'>Georgia</option>
                <option value='HI'>Hawaii</option>
                <option value='ID'>Idaho</option>
                <option value='IL'>Illinois</option>
                <option value='IN'>Indiana</option>
                <option value='IA'>Iowa</option>
                <option value='KS'>Kansas</option>
                <option value='KY'>Kentucky</option>
                <option value='LA'>Louisiana</option>
                <option value='ME'>Maine</option>
                <option value='MD'>Maryland</option>
                <option value='MA'>Massachusetts</option>
                <option value='MI'>Michigan</option>
                <option value='MN'>Minnesota</option>
                <option value='MS'>Missisippi</option>
                <option value='MO'>Missouri</option>
                <option value='MT'>Montana</option>
                <option value='NE'>Nebraska</option>
                <option value='NV'>Nevada</option>
                <option value='NH'>New Hampshire</option>
                <option value='NJ'>New Jersey</option>
                <option value='NM'>New Mexico</option>
                <option value='NY'>New York</option>
                <option value='NC'>North Carolina</option>
                <option value='ND'>North Dakota</option>
                <option value='OH'>Ohio</option>
                <option value='OK'>Oklahoma</option>
                <option value='OR'>Oregon</option>
                <option value='PA'>Pennsylvania</option>
                <option value='RI'>Rhode Island</option>
                <option value='SC'>South Carolina</option>
                <option value='SD'>South Dakota</option>
                <option value='TN'>Tennessee</option>
                <option value='TX'>Texas</option>
                <option value='UT'>Utah</option>
                <option value='VT'>Vermont</option>
                <option value='VA'>Virginia</option>
                <option value='WA'>Washington</option>
                <option value='WV'>West Virginia</option>
                <option value='WI'>Wisconsin</option>
                <option value='WY'>Wyoming</option>
              </Select>
              {props.fieldsErrors.stateSelection.text && <FormHelperText className={classes.error}>{props.fieldsErrors.stateSelection.text}</FormHelperText>}
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box p={1}>
            <FormControlLabel
              className={classes.consentCheckbox}
              control={<Checkbox checked={props.fields.consent} onChange={props.handleConsentChange} name='consent' />}
              label={
                <span className={classes.MuiTypographybodyR}>
                  I agree to the{' '}
                  <strong>
                    <a href={`${process.env.REACT_APP_WEBSITE_URL}/terms-conditions/`} target='_new' rel='noopener noreferrer' className="makeStyles-link">
                      Terms and Conditions
                    </a>
                  </strong>{' '}
                  and{' '}
                  <strong>
                    <a href={`${process.env.REACT_APP_WEBSITE_URL}/privacy/`} target='_new' rel='noopener noreferrer' className="makeStyles-link">
                      Privacy Policy.
                    </a>
                  </strong>
                </span>
              }
            />
            {props.fieldsErrors.consent.status && (
              <Typography variant='body2' component='p' className='error'>
                {props.fieldsErrors.consent.text}
              </Typography>
            )}
          </Box>
        </Grid>
        {activeStates.includes(props.stateOfResidence) && (
          <Grid item xs={12}>
            <Box p={1} pt={3}>
              <Typography variant='h5' component='p' className={classes.MuiTypographybodyR}>
                Great news!
              </Typography>
            </Box>
            <Box p={1}>
              <Typography variant='body1' component='p' className={classes.MuiTypographybodyR}>
                Visionology is now available in {renderLongName()}
                <br />
                Click "Next" to continue.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  questionBox: {
    width: '100%',
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  formControl: {
    width: '100%',
    maxWidth: 320,
  },
  error: {
    color: 'red',
  },
  MuiTypographybodyR: {
    fontSize:"1.2rem",
    fontWeight: "500",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
  },
  consentCheckbox: {
    textAlign: 'left',
    alignItems: 'flex-start',
    '& svg': {
      fill: 'red',
    },
    '& .Mui-checked svg': {
      fill: '#52b252',
    },
  },
  link: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
