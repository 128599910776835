import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
//import bottles from '../../bottles.jpg';
import bottles from '../../KlarityC.png';

export default function FinalScreen(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='h5'>
            Your order for {props.productDetails.product.name} is complete. We'll text you when your doctor reviews and approves your exam.
            { /* Your order is complete. We'll text you when Dr. {props.doctor.lastName} reviews and approves your exam. */ }
          </Typography>
          <img className={classes.bottlesImage} src={bottles} alt='visionology eye drops' />
          <Typography variant='body1' component='p' className={classes.alignCenter}>
            If your doctor needs more information about your condition, our care team will contact you promptly. You'll receive a text when your order ships from the pharmacy.<br/><br/>If you have any questions you can<br/><br/>Call or text us at (844) 637-2020<br/>Email at info@visionology.com
            { /* If Dr. {props.doctor.lastName} needs more information about your condition, our care team will contact you. You'll receive a text when your order
            ships from the pharmacy. */ }
          </Typography>
        </Box>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  questionBox: {
    padding: '16px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    '& h5': {
      textAlign: 'center',
    },
  },
  bottlesImage: {
    maxWidth: 'calc(100% + 32px)',
    margin: '16px auto',
    display: 'block',
  },
  alignCenter: {
    textAlign: 'center',
    fontWeight:'500',
    fontSize: '13px !important',
  },
}));
