import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import visionologyLogoMark from '../../visionology-logo-mark.svg';
import Shofner from '../../shofner.jpg';
import Baum from '../../baum.png';

export default function SelectedDoctor(props) {
  const classes = useStyles();
  const [evaluating, setEvaluating] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setEvaluating(false);
    }, 7000);
  }, []);

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      {evaluating ? (
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p' className='fadeInUp'>
            Finding the eye care practitioner best suited to handle your treatment based on your medical history.
          </Typography>
          <img src={visionologyLogoMark} alt='Visionology loading' className={classes.spinner} />
        </Box>
      ) : (
        <Grid container spacing={0}>
          <Box className={classes.questionBox}>
            <Typography variant='h5' component='p' className='fadeInUp'>
              Great News!
            </Typography>
            <Typography variant='body1' component='p' className='fadeInUp'>
              We have a <strong>Dry Eye</strong> specialist who is going to help you.
            </Typography>
            <Paper className={classes.badge} elevation={4}>
              <Paper className={classes.badgeHole} elevation={0}></Paper>
              <img src={visionologyLogoMark} alt='Visionology Badge Logo' className={classes.badgeLogo} />
              {props.doctor.name === 'Robert Shofner' ? (
                <img src={Shofner} alt={props.doctor.name} className={classes.userProfile} />
              ) : (
                <img src={Baum} alt={props.doctor.name} className={classes.userProfile2} />
              )}
              <Typography variant='h5' component='p'>
                Dr. {props.doctor.name}
              </Typography>
              <Typography variant='h6' component='p'>
                <em>Ophthalmologist</em>
              </Typography>
              <Typography variant='body1' component='p' className={classes.npiNumber}>
                <strong>NPI #:</strong>
                {props.doctor.npi}
              </Typography>
            </Paper>
            <Typography variant='body1' component='p' className='fadeInUp'>
              Click <strong>"Next"</strong> to give Dr. {props.doctor.lastName} your information so we can process your prescription.
            </Typography>
          </Box>
        </Grid>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  questionBox: {
    padding: '16px 16px',
    margin: ' 0 auto',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    '& h5': {
      textAlign: 'center',
    },
  },
  bottlesImage: {
    maxWidth: 'calc(100% + 32px)',
    margin: '16px -16px',
  },
  spinner: {
    width: 60,
    height: 60,
    animation: 'spin .3s infinite',
    margin: 30,
  },
  badge: {
    marginTop: 16,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    overflow: 'hidden',
    position: 'relative',
    background: '#f2f2f2',
    maxWidth: 220,
    margin: '1em auto',
  },
  userProfile: {
    width: 120,
    height: 120,
    margin: 20,
    borderRadius: 8,
  },
  userProfile2: {
    width: 120,
    height: 130,
    margin: 20,
    borderRadius: 8,
  },
  badgeLogo: {
    position: 'absolute',
    opacity: '.1',
    width: '100%',
    bottom: 0,
    left: 0,
    transform: 'translate(-40%, 40%)',
  },
  npiNumber: {
    marginTop: '2em',
  },
  badgeHole: {
    width: 80,
    height: 16,
    borderRadius: 16,
    background: '#fff',
    boxShadow: '0px -2px 4px -1px rgb(0 0 0 / 20%), 0px -4px 5px 0px rgb(0 0 0 / 14%), 0px -1px 10px 0px rgb(0 0 0 / 12%)',
  },
}));
