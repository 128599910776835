import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export default function LoginInfoContact(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p'>
            And also your email address to send you updates about your prescription.
          </Typography>
        </Box>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  questionBox: {
    padding: '16px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  field: {
    maxWidth: 320,
    margin: '0 auto',
  },
}));
