import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';

const medicationList = [
  'Not taking any medications',
  'Accupril',
  'Aciphex',
  'Actos',
  'Adalat CC',
  'Adderall',
  'Albuterol',
  'Alesse 28',
  'Allegra',
  'Allegra-D',
  'Allopurinol',
  'Alphagan',
  'Alprazolam',
  'Altace',
  'Amaryl',
  'Ambien',
  'Amitriptyline HCL',
  'Amoxicillin Trihydrate',
  'Amoxil',
  'Amphetamine',
  'Amyl nitrite',
  'Anabolic Steroids',
  'Aricept',
  'Atenolol',
  'Atenolol (Mylan)',
  'Atrovent',
  'Augmentin',
  'Avandia',
  'Avapro',
  'Azmacort',
  'Bactroban',
  'Baycol',
  'Biaxin',
  'Buspar',
  'Cardizem CD',
  'Cardura',
  'Carisoprodol',
  'Ceftin',
  'Cefzil',
  'Celebrex',
  'Celexa',
  'Cephalexin',
  'Cipro',
  'Claritin',
  'Claritin Reditabs',
  'Claritin-D 12HR',
  'Claritin-D 24HR',
  'Climara',
  'Clonazepam',
  'Clonidine HCL',
  'Cocaine',
  'Combivent',
  'Coumadin',
  'Cozaar',
  'Cyclobenzaprine',
  'Depakote',
  'Detrol',
  'Diazepam',
  'Diflucan',
  'Dilantin',
  'Diovan',
  'Diovan HCT',
  'Effexor XR',
  'Elocon',
  'Ery-Tab',
  'Evista',
  'Flomax',
  'Flonase',
  'Flovent',
  'Folic Acid',
  'Fosamax',
  'Furosemide',
  'Gemfibrozil',
  'Glucophage',
  'Glucotrol XL',
  'Glyburide',
  'Hydrochlorothiazide',
  'Hydrocodone',
  'Hyzaar',
  'Ibuprofen',
  'Imitrex',
  'Isosorbide Mononitrate',
  'K-Dur',
  'Ketamine',
  'Klor-Con 10',
  'Lanoxin',
  'Lescol',
  'Levaquin',
  'Levothroid',
  'Levoxyl',
  'Lipitor',
  'Lo Ovral',
  'Loestrin FE',
  'Lorazepam',
  'Lotensin',
  'Lotrel',
  'Lotrisone',
  'Lysergic Acid Diethylamide',
  'Macrobid',
  'Marijuana',
  'MDMA',
  'Medroxyprogesterone',
  'Mescaline',
  'Methamphetamine',
  'Methylphenidate',
  'Methylprednisolone',
  'Metoprolol Tartrate',
  'Miacalcin',
  'Monopril',
  'Morphine',
  'Naproxen',
  'Naproxen-Sodium',
  'Nasonex',
  'Neurontin',
  'Nicotine',
  'Norvasc',
  'Ortho-Cyclen',
  'Ortho-Tri-Cyclen',
  'Oxycodone',
  'OxyContin',
  'Paxil',
  'Penicillin VK',
  'Pepcid',
  'Phenergan',
  'Plavix',
  'Plendil',
  'Potassium chloride',
  'Pravachol',
  'Prednisone',
  'Premarin',
  'Prempro',
  'Prevacid',
  'Prilosec',
  'Prinivil',
  'Procardia-XL',
  'Promethazine',
  'Propoxyphene',
  'Proventil HFA',
  'Prozac',
  'Psilocybin',
  'Ranitidine',
  'Relafen',
  'Remeron',
  'Risperdal',
  'Ritalin',
  'Rohypnol',
  'Roxicet',
  'Serevent',
  'Serzone',
  'Singulair',
  'Synthroid',
  'Tamoxifen Citrate',
  'Temazepam',
  'Tiazac',
  'Tobradex',
  'Toprol XL',
  'Trazodone',
  'Triamterene',
  'Trimox',
  'Triphasil',
  'Ultram',
  'Valtrex',
  'Vasotec',
  'Veetids',
  'Verapamil',
  'Viagra',
  'Vicoprofen',
  'Vioxx',
  'Warfarin Sodium',
  'Wellbutrin SR',
  'Xalatan',
  'Xenical',
  'Zestoretic',
  'Zestril',
  'Ziac',
  'Zithromax',
  'Zithromax Z-PAK',
  'Zocor',
  'Zoloft',
  'Zyprexa',
  'Zyrtec',
];

const allergyList = [
  'No Known Drug Allergies',
  'Accupril',
  'Aciphex',
  'Actos',
  'Adalat CC',
  'Adderall',
  'Albuterol',
  'Alesse 28',
  'Allegra',
  'Allegra-D',
  'Allopurinol',
  'Alphagan',
  'Alprazolam',
  'Altace',
  'Amaryl',
  'Ambien',
  'Amitriptyline HCL',
  'Amoxicillin Trihydrate',
  'Amoxil',
  'Amphetamine',
  'Amyl nitrite',
  'Anabolic Steroids',
  'Aricept',
  'Atenolol',
  'Atenolol (Mylan)',
  'Atrovent',
  'Augmentin',
  'Avandia',
  'Avapro',
  'Azmacort',
  'Bactroban',
  'Baycol',
  'Balsam of Peru',
  'Biaxin',
  'Buckwheat',
  'Buspar',
  'Cardizem CD',
  'Cardura',
  'Carisoprodol',
  'Ceftin',
  'Cefzil',
  'Celebrex',
  'Celery',
  'Celexa',
  'Cephalexin',
  'Cipro',
  'Claritin',
  'Claritin Reditabs',
  'Claritin-D 12HR',
  'Claritin-D 24HR',
  'Climara',
  'Clonazepam',
  'Clonidine HCL',
  'Cocaine',
  'Combivent',
  'Coumadin',
  'Cozaar',
  'Cyclobenzaprine',
  'Depakote',
  'Detrol',
  'Diazepam',
  'Diflucan',
  'Dilantin',
  'Diovan',
  'Diovan HCT',
  'Effexor XR',
  'Egg',
  'Elocon',
  'Ery-Tab',
  'Evista',
  'Fish',
  'Flomax',
  'Flonase',
  'Flovent',
  'Folic Acid',
  'Fosamax',
  'Fruit',
  'Furosemide',
  'Garlic',
  'Gemfibrozil',
  'Glucophage',
  'Glucotrol XL',
  'Glyburide',
  'Hydrochlorothiazide',
  'Hydrocodone',
  'Hyzaar',
  'Ibuprofen',
  'Imitrex',
  'Isosorbide Mononitrate',
  'K-Dur',
  'Ketamine',
  'Klor-Con 10',
  'Lanoxin',
  'Lescol',
  'Levaquin',
  'Levothroid',
  'Levoxyl',
  'Lipitor',
  'Lo Ovral',
  'Loestrin FE',
  'Lorazepam',
  'Lotensin',
  'Lotrel',
  'Lotrisone',
  'Lysergic Acid Diethylamide',
  'Macrobid',
  'Maize',
  'Marijuana',
  'MDMA',
  'Medroxyprogesterone',
  'Mescaline',
  'Methamphetamine',
  'Methylphenidate',
  'Methylprednisolone',
  'Metoprolol Tartrate',
  'Miacalcin',
  'Milk',
  'Monopril',
  'Morphine',
  'Mustard',
  'Naproxen',
  'Naproxen-Sodium',
  'Nasonex',
  'Neurontin',
  'Nicotine',
  'Norvasc',
  'Oats',
  'Ortho-Cyclen',
  'Ortho-Tri-Cyclen',
  'Oxycodone',
  'OxyContin',
  'Paxil',
  'Peanut',
  'Penicillin VK',
  'Pepcid',
  'Phenergan',
  'Plavix',
  'Plendil',
  'Potassium chloride',
  'Poultry',
  'Pravachol',
  'Prednisone',
  'Premarin',
  'Prempro',
  'Prevacid',
  'Prilosec',
  'Prinivil',
  'Procardia-XL',
  'Promethazine',
  'Propoxyphene',
  'Proventil HFA',
  'Prozac',
  'Psilocybin',
  'Ranitidine',
  'Red meat',
  'Relafen',
  'Remeron',
  'Rice',
  'Risperdal',
  'Ritalin',
  'Rohypnol',
  'Roxicet',
  'Serevent',
  'Serzone',
  'Sesame',
  'Shellfish',
  'Singulair',
  'Soy',
  'Sulfites',
  'Synthroid',
  'Tamoxifen Citrate',
  'Tartrazine',
  'Temazepam',
  'Tiazac',
  'Tobradex',
  'Toprol XL',
  'Trazodone',
  'Tree nut',
  'Triamterene',
  'Trimox',
  'Triphasil',
  'Ultram',
  'Valtrex',
  'Vasotec',
  'Veetids',
  'Verapamil',
  'Viagra',
  'Vicoprofen',
  'Vioxx',
  'Warfarin Sodium',
  'Wellbutrin SR',
  'Wheat',
  'Xalatan',
  'Xenical',
  'Zestoretic',
  'Zestril',
  'Ziac',
  'Zithromax',
  'Zithromax Z-PAK',
  'Zocor',
  'Zoloft',
  'Zyprexa',
  'Zyrtec',
];

export default function Review(props) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState({
    personalInfo: false,
    medicalHistory: false,
    medicationsAllergies: false,
    dryEyeScores: false,
    otherInfo: false,
  });
  const [dob, setDob] = useState({
    month: props.fields.dob !== null ? props.fields.dob.substring(0, 2) : '',
    day: props.fields.dob !== null ? props.fields.dob.substring(3, 5) : '',
    year: props.fields.dob !== null ? props.fields.dob.substring(6, 10) : '',
  });
  const {
    fields,
    uncDemsScore,
    uncDemsScore2,
    medications,
    allergies,
    gender,
    pregnant,
    smoker,
    patientHistory,
    contactLens,
    handleTextFieldChange,
    handleDateChange,
    validateFirstName,
    validateLastName,
    validatePhone,
    validateEmail,
    fieldsErrors,
    handlePatientHistory,
    handleUncTextFieldChange,
    handleUnc2TextFieldChange,
    handleMedicationTextFieldChange,
    handleAllergyTextFieldChange,
    handlePregnant,
    handleSmoker,
    handleContactLens,
    handleGender,
    apiErrorPatient,
  } = props;

  const handleEditMode = (prop) => {
    setEditMode({...editMode, [prop]: !editMode[prop]});
  };

  const handleDobTextChange = (prop) => (event) => {
    setDob({...dob, [prop]: event.target.value});
  };

  useEffect(() => {
    handleDateChange(`${dob.year}/${dob.month}/${dob.day}`);
  }, [dob]);

  useEffect(() => {
    //console.log(editMode);
  }, [editMode]);

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box className={classes.questionBox}>
            <Typography variant='h5' component='p'>
              Please review the information you've provided carefully and click "Next" to proceed.
            </Typography>
          </Box>
        </Grid>
        {apiErrorPatient.status && (
          <Grid item xs={12}>
            <Box className={classes.section}>
              <span className={classes.error}>{apiErrorPatient.text}</span>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Box className={classes.section}>
            <Typography variant='body2' component='p' className={classes.sectionTitle}>
              <strong>Personal Information</strong>
              <Button className={classes.editSubmit} autoFocus color={editMode.personalInfo ? 'primary' : 'secondary'} onClick={() => handleEditMode('personalInfo')}>
                {editMode.personalInfo ? 'Submit' : 'Edit'}
              </Button>
            </Typography>
            {editMode.personalInfo ? (
              <>
                <TextField
                  id='first-name'
                  label='First name'
                  variant='outlined'
                  color='secondary'
                  value={fields.firstName}
                  onChange={handleTextFieldChange('firstName')}
                  onBlur={() => validateFirstName()}
                  error={fieldsErrors.firstName.status}
                  helperText={fieldsErrors.firstName.text}
                  required
                  fullWidth
                  margin='dense'
                />
                <TextField
                  id='last-name'
                  label='Last name'
                  variant='outlined'
                  color='secondary'
                  value={fields.lastName}
                  onChange={handleTextFieldChange('lastName')}
                  onBlur={() => validateLastName()}
                  error={fieldsErrors.lastName.status}
                  helperText={fieldsErrors.lastName.text}
                  required
                  fullWidth
                  margin='dense'
                />
                <InputMask
                  mask='(999) 999 9999'
                  value={fields.phoneNumber}
                  onChange={handleTextFieldChange('phoneNumber')}
                  onBlur={() => validatePhone()}
                  disabled={false}
                  maskChar=' '>
                  {() => (
                    <TextField
                      id='phone-number'
                      label='Phone number'
                      variant='outlined'
                      color='secondary'
                      error={fieldsErrors.phoneNumber.status}
                      helperText={fieldsErrors.phoneNumber.text}
                      required
                      fullWidth
                      margin='dense'
                    />
                  )}
                </InputMask>
                <TextField
                  id='email-address'
                  label='Email Address'
                  variant='outlined'
                  color='secondary'
                  value={fields.emailAddress}
                  onChange={handleTextFieldChange('emailAddress')}
                  onBlur={() => validateEmail()}
                  error={fieldsErrors.emailAddress.status}
                  helperText={fieldsErrors.emailAddress.text}
                  required
                  fullWidth
                  margin='dense'
                />
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      id='dob-month'
                      label='Month'
                      className={classes.dobField}
                      variant='outlined'
                      color='secondary'
                      value={dob.month}
                      placeholder='MM'
                      onChange={handleDobTextChange('month')}
                      required
                      fullWidth
                      inputProps={{maxLength: 2}}
                      error={fieldsErrors.dob.status}
                      margin='dense'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id='dob-day'
                      label='Day'
                      className={classes.dobField}
                      variant='outlined'
                      color='secondary'
                      value={dob.day}
                      placeholder='DD'
                      onChange={handleDobTextChange('day')}
                      required
                      fullWidth
                      inputProps={{maxLength: 2}}
                      error={fieldsErrors.dob.status}
                      margin='dense'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id='dob-year'
                      label='Year'
                      className={classes.dobFieldYear}
                      variant='outlined'
                      color='secondary'
                      value={dob.year}
                      placeholder='YYYY'
                      onChange={handleDobTextChange('year')}
                      required
                      fullWidth
                      inputProps={{maxLength: 4}}
                      error={fieldsErrors.dob.status}
                      margin='dense'
                    />
                  </Grid>
                </Grid>
                {fieldsErrors.dob.text && (
                  <Typography className='error' variant='body2' component='p'>
                    {fieldsErrors.dob.text}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Name:</span> {fields.firstName} {fields.lastName}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Phone:</span> {fields.phoneNumber}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Email:</span> {fields.emailAddress}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Date of Birth:</span> {fields.dob}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.section}>
            <Typography variant='body2' component='p' className={classes.sectionTitle}>
              <strong>Medical History</strong>
              <Button className={classes.editSubmit} color={editMode.medicalHistory ? 'primary' : 'secondary'} onClick={() => handleEditMode('medicalHistory')}>
                {editMode.medicalHistory ? 'Submit' : 'Edit'}
              </Button>
            </Typography>
            {editMode.medicalHistory ? (
              <>
                <div className={classes.flexed}>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Dry Eye Disease:</span>
                  </Typography>
                  <RadioGroup
                    className={classes.flexed}
                    aria-label='dryeye'
                    name='dryeye'
                    value={patientHistory.dryeye === true ? 'true' : 'false'}
                    onChange={handlePatientHistory('dryeye')}>
                    <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                    <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                  </RadioGroup>
                </div>
                <div className={classes.flexed}>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Glaucoma:</span>
                  </Typography>
                  <RadioGroup
                    className={classes.flexed}
                    aria-label='glaucoma'
                    name='glaucoma'
                    value={patientHistory.glaucoma === true ? 'true' : 'false'}
                    onChange={handlePatientHistory('glaucoma')}>
                    <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                    <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                  </RadioGroup>
                </div>
                <div className={classes.flexed}>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Cataracts:</span>
                  </Typography>
                  <RadioGroup
                    className={classes.flexed}
                    aria-label='cataracts'
                    name='cataracts'
                    value={patientHistory.cataracts === true ? 'true' : 'false'}
                    onChange={handlePatientHistory('cataracts')}>
                    <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                    <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                  </RadioGroup>
                </div>
                <div className={classes.flexed}>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Retinal Disease:</span>
                  </Typography>
                  <RadioGroup
                    className={classes.flexed}
                    aria-label='retinal detachment or retinal disease'
                    name='retinal'
                    value={patientHistory.retinal === true ? 'true' : 'false'}
                    onChange={handlePatientHistory('retinal')}>
                    <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                    <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                  </RadioGroup>
                </div>
                <div className={classes.flexed}>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Other:</span>
                  </Typography>
                  <RadioGroup
                    className={classes.flexed}
                    aria-label='other eye condition'
                    name='other'
                    value={patientHistory.other === true ? 'true' : 'false'}
                    onChange={handlePatientHistory('other')}>
                    <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                    <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                  </RadioGroup>
                </div>
              </>
            ) : (
              <>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Dry Eye Disease:</span> {patientHistory.dryeye ? 'Yes' : 'No'}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Glaucoma:</span> {patientHistory.glaucoma ? 'Yes' : 'No'}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Cataracts:</span> {patientHistory.cataracts ? 'Yes' : 'No'}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Retinal Disease:</span> {patientHistory.retinal ? 'Yes' : 'No'}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Other:</span> {patientHistory.other ? patientHistory.explanation : 'No'}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.section}>
            <Typography variant='body2' component='p' className={classes.sectionTitle}>
              <strong>Medications & Allergies</strong>
              <Button className={classes.editSubmit} color={editMode.medicationsAllergies ? 'primary' : 'secondary'} onClick={() => handleEditMode('medicationsAllergies')}>
                {editMode.medicationsAllergies ? 'Submit' : 'Edit'}
              </Button>
            </Typography>
            {editMode.medicationsAllergies ? (
              <>
                <FormControl className={`${classes.fullWidth} reviewauto`}>
                  <Autocomplete
                    id='medication-selectbox'
                    className={classes.selectBox}
                    freeSolo
                    filterSelectedOptions
                    multiple
                    value={medications}
                    onChange={(event, value) => props.handleSelection(event, value)}
                    options={medicationList}
                    renderOption={(medication) => medication}
                    getOptionLabel={(medication) => medication}
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' placeholder='Select from the list or start typing...' margin='normal' fullWidth autoFocus />
                    )}
                  />
                </FormControl>
                <FormControl className={`${classes.fullWidth} reviewauto`}>
                  <Autocomplete
                    id='allergies-selectbox'
                    className={classes.selectBox}
                    freeSolo
                    filterSelectedOptions
                    multiple
                    value={allergies}
                    onChange={(event, value) => props.handleAllergySelection(event, value)}
                    options={allergyList}
                    renderOption={(allergy) => allergy}
                    getOptionLabel={(allergy) => allergy}
                    renderInput={(params) => (
                      <TextField {...params} variant='outlined' placeholder='Select from the list or start typing...' margin='normal' fullWidth autoFocus />
                    )}
                  />
                </FormControl>
                <TextField
                  id='medications'
                  label='Medications'
                  variant='outlined'
                  color='secondary'
                  value={medications}
                  onChange={handleMedicationTextFieldChange()}
                  required
                  fullWidth
                  margin='dense'
                  className={classes.visibilityHidden}
                />
                <TextField
                  id='allergies'
                  label='Allergies'
                  variant='outlined'
                  color='secondary'
                  value={allergies}
                  onChange={handleAllergyTextFieldChange()}
                  required
                  fullWidth
                  margin='dense'
                  className={classes.visibilityHidden}
                />
              </>
            ) : (
              <>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Medications:</span> {medications.join(", ")}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Allergies:</span> {allergies.join(", ")}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.section}>
            <Typography variant='body2' component='p' className={classes.sectionTitle}>
              <strong>Dry Eye Scores</strong>
            </Typography>
            <Typography variant='body1' component='p'>
              <span className={classes.fontWeight500}>Symptoms:</span> {uncDemsScore}
            </Typography>
            <Typography variant='body1' component='p'>
              <span className={classes.fontWeight500}>Affect on daily life:</span> {uncDemsScore2}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.section}>
            <Typography variant='body2' component='p' className={classes.sectionTitle}>
              <strong>Other Info</strong>
              <Button className={classes.editSubmit} color={editMode.otherInfo ? 'primary' : 'secondary'} onClick={() => handleEditMode('otherInfo')}>
                {editMode.otherInfo ? 'Submit' : 'Edit'}
              </Button>
            </Typography>
            {editMode.otherInfo ? (
              <>
                <div className={classes.buttonContainer}>
                  <Box p={1}>
                    <Button variant={gender.female} color='secondary' onClick={handleGender('female')}>
                      Female
                    </Button>
                  </Box>
                  <Box p={1}>
                    <Button variant={gender.male} color='secondary' onClick={handleGender('male')}>
                      Male
                    </Button>
                  </Box>
                </div>
                {gender.female === 'contained' && (
                  <div className={classes.flexed}>
                    <Typography variant='body1' component='p'>
                      <span className={classes.fontWeight500}>Pregnant:</span>
                    </Typography>
                    <RadioGroup
                      className={classes.flexed}
                      aria-label='pregnant'
                      name='pregnant'
                      value={pregnant === true ? 'true' : 'false'}
                      onChange={handlePregnant()}>
                      <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                      <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                    </RadioGroup>
                  </div>
                )}
                <div className={classes.flexed}>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Smoker:</span>
                  </Typography>
                  <RadioGroup className={classes.flexed} aria-label='smoker' name='smoker' value={smoker === true ? 'true' : 'false'} onChange={handleSmoker()}>
                    <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                    <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                  </RadioGroup>
                </div>
                <div className={classes.flexed}>
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Contact Lens:</span>
                  </Typography>
                  <RadioGroup
                    className={classes.flexed}
                    aria-label='contact lens'
                    name='contactLens'
                    value={contactLens === true ? 'true' : 'false'}
                    onChange={handleContactLens()}>
                    <FormControlLabel className="nobtn" value='true' control={<Radio size='small' className={classes.squished} />} label='Yes' />
                    <FormControlLabel className="nobtn" value='false' control={<Radio size='small' className={classes.squished} />} label='No' />
                  </RadioGroup>
                </div>
              </>
            ) : (
              <>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Gender:</span> {gender.female === 'contained' ? 'Female' : 'Male'}
                </Typography>
                {gender.female === 'contained' && (
                  <Typography variant='body1' component='p'>
                    <span className={classes.fontWeight500}>Pregnant:</span> {pregnant ? 'Yes' : 'No'}
                  </Typography>
                )}
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Smoker:</span> {smoker ? 'Yes' : 'No'}
                </Typography>
                <Typography variant='body1' component='p'>
                  <span className={classes.fontWeight500}>Contact Lenses:</span> {contactLens ? 'Yes' : 'No'}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  questionBox: {
    width: '100%',
    padding: '16px 16px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    boxSizing: 'border-box',
  },
  section: {
    padding: '16px',
  },
  sectionTitle: {
    borderBottom: '1px solid #666',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  squished: {
    padding: '9px 4px 9px 9px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    color: 'red',
  },
  editSubmit: {
    border: "2px solid #001141",
    color: "#001141",
    padding: "4px 18px !important",
    marginBottom: "8px",
    paddingTop: "8px !important",
  },
  fontWeight500: {
    fontWeight: 500,
  },
  visibilityHidden: {
    display: "none"
  },
  fullWidth: {
    width: '100%'
  }
}));
