import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import bottles from '../../KlarityC.png';
import visionologyLogoMark from '../../visionology-logo-mark.svg';

export default function RequestPrescription(props) {
  const classes = useStyles();
  const [evaluating, setEvaluating] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setEvaluating(false);
    }, 3000);
  }, []);

  return (
    <div className={classes.root} noValidate autoComplete='off'>
      {evaluating ? (
        <Box className={classes.questionBox}>
          <Typography variant='h5' component='p' className='fadeInUp'>
            Finding the right treatment plan and medications for you based on your symptoms.
          </Typography>
          <img src={visionologyLogoMark} alt='Visionology loading' className={classes.spinner} />
        </Box>
      ) : (
        <Grid container spacing={0}>
          <Box className={classes.questionBox}>
            <Typography variant='h5' component='h5'>
              You may suffer from <strong>Dry Eye Disease</strong> and qualify for our <strong>{props.productDetails.product.name}</strong> eye drops trial.
            </Typography>
            <img className={classes.bottlesImage} src={bottles} alt='visionology eye drops' />
            <Typography variant='h5' component='h5' className={classes.questionTitleBoxH6}>
              Click "Next" to enter your medical information and find the right expert.
            </Typography>
          </Box>
        </Grid>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  questionBox: {
    padding: '16px 16px',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
    '& h5': {
      textAlign: 'center',
    },
  },
  questionTitleBoxH6: {
    fontFamily: 'var(--jost) !important',
    color: '#27345e !important',
    fontWeight: '500',
    fontSize: '1.4rem',
    lineHeight: '2.19rem',
    textAlign: 'center',
    fontWeight: '600',
  },
  bottlesImage: {
    width: 'calc(100% + 32px)',
    margin: '16px -16px',
    maxWidth: 320,
  },
  spinner: {
    width: 60,
    height: 60,
    animation: 'spin .3s infinite',
    margin: 30,
  },
  
}));
