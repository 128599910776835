import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Instructions() {
  const classes = useStyles();

  return (
    <Box p={2}>
      <Typography variant='h5' component='h5' className={classes.muiTypographynova}>
        Dry Eye Assessment
      </Typography>
      <Typography variant='h6' component='p' className={classes.muiTypographyh6nova}>
        How do your <strong>Dry Eye Symptoms</strong> affect your daily life?
      </Typography>
      <Typography variant='h6' component='p' className={classes.muiTypographyh6nova}>
        <strong>Dry Eye Symptoms</strong> like pain, burning, tearing, grittiness, and sensitivity to light may affect your ability to read, drive, work on a
        computer or watch TV.
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  instruction: {
    marginBottom: '1em',
  },
  muiTypographynova: {
    marginBottom: '1em',
    marginTop: '32px',
    fontFamily: "Ibarra Real Nova !important",
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    fontSize: '2rem !important',
    lineHeight: '2.2rem !important',
    color: '#27345e !important',
  },
  muiTypographyh6nova: {
    marginBottom: '1em',
    fontWeight: '500 !important',
    letterSpacing: '0.0075em !important',
    color: '#27345e !important',
    fontSize: '1.4rem !important',
    lineHeight: '2.2rem !important',
    fontFamily: "Jost !important",
  },
}));
